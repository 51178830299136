import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Masonry from 'react-masonry-component';
// Layout
import Header1 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';
import SimpleReactLightbox, { SRLWrapper, useLightbox } from 'simple-react-lightbox';
// Elements
import axios from "axios";
import LoadingSpinner from "../../LoadingSpinner";
// Images
import Banner2 from '../../images/banner/banner2.jpg';
import Banner1 from "../../images/banner/banner1.jpg"
const aktuelUrl = "https://getjob.stechomeyazilim.info:5101/getNewsCategory/select";

const imgUrl = "https://berlinesnafadmin.stechomeyazilim.com";
class ProjectGridWide extends Component {



	constructor(props) {
		super(props)
		this.state = {

			aktuelArray: [],

			aktuelArray2: [],
				isLoading: true
		}
	}

	componentDidMount() {

		this.getAktuel()

	}
	getAktuel = async () => {
		try {
			axios.get(aktuelUrl).then((res) => {
				console.log("res123", res.data)
				this.setState({ aktuelArray: res.data, isLoading: false  }, () => {
					let filterData = this.state.aktuelArray.filter(x => x.ID == 2)
					this.setState({ aktuelArray2: filterData });
				});
			});
		} catch (error) {
			console.log("error" + error);
		}
	};
	change = async (Name) => {
		this.setState(
			{
				aktuelArray2: [],
			});

		let filterData = this.state.aktuelArray.filter(x => x.News_Category_Title == Name)
		this.setState({ aktuelArray2: filterData });
		console.log('filtrele', filterData);

	};


	render() {
		return (
			<>
				<Header1 />

				{/* <!-- Content --> */}
				<div className="page-content bg-white">

					<div className="page-banner ovbl-dark page-banner-lg ovdt1 overlay-dotted no-pt parallax" style={{ backgroundImage: "url(" + Banner2 + ")" }}>
						<div className="container">
							<div className="page-banner-entry">
								<span className="banner-sm-title">Aktüel</span>
								<h1 className="text-white"></h1>
							</div>
						</div>
					</div>

					<div className="content-block" id="content-area">



						<div className="section-area bg-gray bg-fix text-white">


							<Tabs>
								<div className="bg-primary">
									<div className="container">
										<TabList className="nav misson-tabs">
											{this.state.aktuelArray.map(item => (

												<Tab onClick={() => this.change(item.News_Category_Title)} value={item.ID}>{item.News_Category_Title}</Tab>
											))}
										</TabList>

									</div>
								</div>


								{this.state.aktuelArray2.length > 0 ? this.state.aktuelArray2.map(item2 => (


									<div className="row" style={{ margin: 10 }}>



										{item2.News.map(item3 => (

											<div className="col-lg-3 col-md-4 col-sm-12 col-4 wow fadeIn" data-wow-delay="0.2s">
												<div className="feature-container feature-bx1" >


													<img style={{ width: '100%' }} src={imgUrl + item3.News_Image} alt="" />


													<Link style={{ justifyContent: 'center', display: 'flex', color: 'black' }}
														to={ "/aktueldetay/" + (item3.News_Title).replace(/\s+/g, '-')  }  state= {{ _item: item3 }}>



														<div className="icon-content">
															<h4 style={{ color: 'black' }} className="ttr-tilte"> {item3.News_Title} </h4>


															<p style={{ color: 'black' }}> {item3.News_Short_Desc} </p>


														</div>
													</Link>
													<div className="bg-img" style={{ backgroundColor: '#5096C9' }}></div>
												</div>
											</div>


										))}
									</div>)) :

									null}

							</Tabs>
						</div>
					</div>
				</div>
				<Footer1 />



				<LoadingSpinner show={this.state.isLoading} />
			</>
		);
	}
}

export default ProjectGridWide;