import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header2 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';

// Images
import Banner4 from "../../images/banner/banner4.jpg"
import ProductPic1 from "../../images/product/pic1.jpg"
import ProductPic2 from "../../images/product/pic2.jpg"
import ProductPic3 from "../../images/product/pic3.jpg"
import ProductPic4 from "../../images/product/pic4.jpg"
import ProductPic5 from "../../images/product/pic5.jpg"
import Icon1 from '../../images/icon/contact/icon1.png';
import Icon2 from '../../images/icon/contact/icon2.png';
import Icon3 from '../../images/icon/contact/icon3.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faLocation, faMailForward, faMobile } from '@fortawesome/free-solid-svg-icons'

const adres = <FontAwesomeIcon icon={faLocation} />
const tel = <FontAwesomeIcon icon={faMobile} />
const mail = <FontAwesomeIcon icon={faMailForward} />
class ShopCart extends Component {

	render() {
		return (
			<>
				<Header2 />

				{/* <!-- Content --> */}
				<div className="page-content bg-white">

					<div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + Banner4 + ")" }}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">Rehber Detay</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
										<li>Rehber Detay</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className="content-block" id="content-area">

						<div className="section-area section-sp2 bg-gray">
							<div className="container">
								{/* <!-- Shopping cart table --> */}
								<div className="table-responsive cart-table wow fadeIn" data-wow-delay="0.8s">
									<table className="table table-cards align-items-center">
										<thead>
											<tr>
												<th scope="col" className="sort" data-sort="product">Şirket İsmi</th>
												<th scope="col" className="sort" data-sort="price">	
												<img style={{ width: 15 }} src={Icon3} alt="" className="mCS_img_loaded" /> 
</th>
												<th scope="col" className="sort" data-sort="size"><img style={{ width: 20 }} src={Icon1} alt="" className="mCS_img_loaded" />Telefon Numarası</th>
												<th scope="col">	<img style={{ width: 20 }} src={Icon2} alt="" className="mCS_img_loaded" />Email Adresi</th>

											</tr>
										</thead>
										<tbody className="list">
											<tr>
												<th scope="row">
													<div className="media align-items-center">

														<div className="media-body">
															<span className="title">Vitay Plegedinst</span>

														</div>
													</div>
												</th>
												<td >
												
													Adressss
												</td>
												<td>
													
													<span className="status">0300200020</span>
												</td>
												<td>
												

													<span className="status">xxxx@gmail.com</span>		</td>

											</tr>
											<tr className="table-divider"></tr>
											<tr>
												<th scope="row">
													<div className="media align-items-center">

														<div className="media-body">
															<span className="title">Vitay Plegedinst</span>

														</div>
													</div>
												</th>
												<td >
													<img style={{ width: 20 }} src={Icon3} alt="" className="mCS_img_loaded" />

													Adressss
												</td>
												<td>
													<img style={{ width: 20 }} src={Icon1} alt="" className="mCS_img_loaded" />
													<span className="status">0300200020</span>
												</td>
												<td>
													<img style={{ width: 20 }} src={Icon2} alt="" className="mCS_img_loaded" />

													<span className="status">xxxx@gmail.com</span>		</td>

											</tr>
											<tr className="table-divider"></tr>

											<tr>
												<th scope="row">
													<div className="media align-items-center">

														<div className="media-body">
															<span className="title">Impuls Apotheke</span>

														</div>
													</div>
												</th>
												<td>
													<img style={{ width: 20 }} src={Icon3} alt="" className="mCS_img_loaded" />

													Adressss
												</td>
												<td>
													<img style={{ width: 20 }} src={Icon1} alt="" className="mCS_img_loaded" />
													<span className="status">0300200020</span>
												</td>
												<td>
													<img style={{ width: 20 }} src={Icon2} alt="" className="mCS_img_loaded" />

													<span className="status">xxxx@gmail.com</span>		</td>

											</tr>
											<tr className="table-divider"></tr>

										</tbody>
									</table>
								</div>
								{/* <!-- Cart information --> */}

							</div>
						</div>

					</div>

				</div>

				<Footer1 />

			</>
		);
	}
}

export default ShopCart;