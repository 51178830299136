import React, { Component } from 'react';
import { Link } from 'react-router-dom';



import Footer1 from '../layout/footer/footer1';

import Banner1 from '../../images/banner/banner1.jpg';

import Header2 from '../layout/header/header2';
import axios from "axios";

import { withParamsAndNavigate } from "../../getParamsAndNavigate.js";
import { Button } from 'react-bootstrap';
const imgUrl='https://berlinesnafadmin.stechomeyazilim.com/Uploads/';
const rehberUrl='https://getjob.stechomeyazilim.info:5101/getSearchCompany/select/:id';
class Services1 extends Component {
    constructor(props) {
		super(props)
		this.state = {
		  filterData:[],
		  searchCheck:false
		}
	}


	

	change_text = async (keyword) => {

			try {

				if(keyword.target.value.length >0){
					await axios.get(`https://getjob.stechomeyazilim.info:5101/getSearchCompany/select/${this.props.location.state._item2.ID}/${keyword.target.value}`).then((res) => {
						console.log("res123", res.data)
						this.setState({filterData:res.data,searchCheck:true })
		
					});
				}else{
					this.setState({searchCheck:false })
	
				}
				
			} catch (error) {
				console.log("error" + error);
			}


		
	};
	render() {
		return (
			<>
				<Header2 />

				{/* <!-- Content --> */}
				<div className="page-content bg-white">

					<div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + Banner1 + ")" }}>
						<div className="container">

							<div className="page-banner-entry">
								<h1 className="text-white">Rehber</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
										<li>Rehber</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className="content-block" id="content-area">

						<div className="section-area bg-gray section-sp2">
							<div className="container">


								<div className="search-bx style-1 heading-bx  " >
									<form role="search" method="post">
										<div className="input-group">
											<input onChange={(e) => this.change_text(e)} name="text" className="form-control" placeholder="Firma ara" type="text" />
											<span className="input-group-btn">
												<button type="submit" className="fa fa-search text-primary"></button>
											</span>
										</div>
									</form>

								</div>

								<div className="row">

								
								{
									
									this.props.location.state._item2.Company.length!=0 ?
									this.state.searchCheck == true ? this.state.filterData.map(item3 => (
								
					
										<div className="col-lg-6 col-md-6 col-sm-6 col-12 wow fadeIn" data-wow-delay="0.2s">

							
										<div className="feature-container feature-bx1" style={{ backgroundColor: item3.Company_BackgroundColor }} >
								
									
											<div className="icon-content">
											<img style={{width:100,height:100,borderRadius:50}}  src={imgUrl+item3.Company_Image} alt=""/>
												<h4 className="ttr-tilte" style={{ color: item3.Company_TitleColor}}>{item3.Company_Name}</h4>
												<p style={{  color: item3.Company_TitleColor }}><i className="fa fa-location-arrow"></i>{item3.Company_Adress}</p>
												<p style={{  color: item3.Company_TitleColor}}><i className="fa fa-mobile"></i> {item3.Company_TelephoneNumber}</p>
											
												<p style={{  color: item3.Company_TitleColor}}><i className="fa fa-envelope"></i> {item3.Company_MailAdress}</p>
											</div>
										
										<div className="bg-img" style={{ backgroundColor: '#5096C9' }}></div>
										</div>
									</div>
								
								)) : this.props.location.state._item2.Company.map(item => (
								
					
									<div className="col-lg-6 col-md-6 col-sm-6 col-12 wow fadeIn" data-wow-delay="0.2s">

							
										<div className="feature-container feature-bx1" style={{ backgroundColor: item.Company_BackgroundColor }} >
								
									
											<div className="icon-content">
											<img style={{width:100,height:100,borderRadius:50}}  src={imgUrl+item.Company_Image} alt=""/>
												<h4 className="ttr-tilte" style={{ color: item.Company_TitleColor}}>{item.Company_Name}</h4>
												<p style={{  color: item.Company_TitleColor }}><i className="fa fa-location-arrow"></i>{item.Company_Adress}</p>
												<p style={{  color: item.Company_TitleColor}}><i className="fa fa-mobile"></i> {item.Company_TelephoneNumber}</p>
											
												<p style={{  color: item.Company_TitleColor}}><i className="fa fa-envelope"></i> {item.Company_MailAdress}</p>
											</div>
										
										<div className="bg-img" style={{ backgroundColor: '#5096C9' }}></div>
										</div>
									</div>
							
							)): 	<div className="col-lg-6 col-md-6 col-sm-6 col-12 wow fadeIn" data-wow-delay="0.2s"> Seçilen kategoriye ait şirket bulunmamaktadır	</div>
						}
								</div>
							</div>


							
						</div>




					</div>

				</div>

				<Footer1 />

			</>
		);
	}
}

export default withParamsAndNavigate(Services1);
