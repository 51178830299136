import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Layout
import Header1 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';
// Images
import Banner4 from "../../images/banner/banner4.jpg"
import axios from "axios";
import Moment from 'moment'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const rehberUrl = "https://getjob.stechomeyazilim.info:5101/getCompanyCategory/select/";
const rehberUrlpost = "https://getjob.stechomeyazilim.info:5101/postCompany/send"

class ShopCheckout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            tel: "",
            address: "",
            selectedValue: "",
            email: "",
            rehberKategoriArray: [],
            ilanSubArray:[]
        }
    }
    postRehber = async (e) => {
        e.preventDefault()
        try {

            console.log("lsdnf", this.state.selectedValue)
            var testDateUtc = Moment.utc(new Date());
            var localDate = Moment(testDateUtc).local();
            let data = {
                Company_Name: this.state.name,
                Company_Image: null,
                Company_Adress: this.state.address,
                Company_Location_Lat: null,
                Company_Location_Lng: null,
                Users_ID: 20,

                Company_Add_DateTime: localDate,
                Company_Advertisement_Users_Complated_ID: null,
                Company_SubCategory_ID: this.state.categorySubID,
                Company_TelephoneNumber: this.state.tel,
         
                Company_MailAdress: this.state.email,


            }
            axios.post(rehberUrlpost, data).then(async (res) => {
                this.showToast('BerlinEsnaf', "İlanınız başarıyla sisteme yüklenmiştir!", 'success')
            })
        }

        catch (error) {
            console.log("errorccAS" + error)
        }
    }

    
    componentDidMount() {

        this.getRehberKategori();

    }

    getRehberKategori = async () => {
        try {
            axios.get(rehberUrl).then((res) => {
                console.log("res123", res.data)
                this.setState(
                    {
                        rehberKategoriArray: res.data,

                    });
            });
        } catch (error) {
            console.log("error" + error);
        }
    };
    changeName = (e) => {
        this.setState({ name: e.target.value });
    }
    changeTel = (e) => {
        this.setState({ tel: e.target.value });
    }
    changeAddress = (e) => {
        this.setState({ address: e.target.value });
    }
    changeEmail = (e) => {
        this.setState({ email: e.target.value });
    }

    handleChange = (selectedOptions) => {
        this.setState({ selectedOptions });
    }

    handleChangeSelectedSubCategory= (e) => {
        console.log("lnkfsdklf", e.target)

        var index = e.nativeEvent.target.selectedIndex;
        var text = e.nativeEvent.target[index].text;

        this.setState({
            categorySubID: e.target.value,
            selectedSubValue: text
        })
    }


    handleChangeSelected = (e) => {
        console.log("lnkfsdklf", e.target)

        var index = e.nativeEvent.target.selectedIndex;
        var text = e.nativeEvent.target[index].text;

        let subCategory = this.state.rehberKategoriArray.filter((x)=> x.Company_Category_Title == e.nativeEvent.target[index].text)
    

        this.setState({
            ilanSubArray : subCategory[0].Company_SubCategory,
            categoryID: e.target.value,
            selectedValue: text
        })
    }

    showToast = (event, value, type) => {
        if (type == "false") {
            toast.error(value, {
                position: "bottom-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        } else {
            toast.success(value, {
                position: "bottom-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            const{params,navigate} = this.props;
            navigate('/ilan/')
        }
    }

    render() {

        return (
            <>
                <Header1 />

                {/* <!-- Content --> */}
                <div className="page-content bg-white">

                    <div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + Banner4 + ")" }}>
                        <div className="container">
                            <div className="page-banner-entry">
                                <h1 className="text-white">Rehbere Başvur</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
                                        <li>Rehbere Başvur</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block" id="content-area">

                        <div className="section-area section-sp1 bg-gray">
                            <div className="container">
                                <div className="row row-grid checkout-area">
                                    <div className="content-block" id="content-area">

                                        <div className="section-area bg-gray section-sp2">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-7">
                                                        <div className="heading-bx">
                                                            <h6 className="title-ext m-b0">Rehber</h6>
                                                            <h3 className="title-head m-b0">Rehber Başvurusu</h3>
                                                        </div>
                                                        <div className="row m-b30">
                                                            <div className="col-md-12">
                                                                <form className="ajax-form form-area-box get-in-touch" method='post' to='#'>
                                                                    <div className="ajax-message"></div>
                                                                    <div className="row placeani">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <div className="input-group">
                                                                                    <select onChange={(e) => this.handleChangeSelected(e)} name="quote-request-reach">
                                                                                        <option value="">Kategori Seçiniz</option>

                                                                                        {this.state.rehberKategoriArray.map(item => (
                                                                                          
                                                                                                <option name="selectedValue" label={item.Company_Category_Title} value={item.ID}>{item.Company_Category_Title}</option>
                                                                                        ))}

                                                                                    </select>

                                                                                    {this.state.ilanSubArray.length > 0 ? <select onChange={(e) => this.handleChangeSelectedSubCategory(e)} name="quote-request-reach">
                                                                                        <option value="">AltKategori Seçiniz</option>
                                                                                        {this.state.ilanSubArray.map(item => (
                                                                                        
                                                                                                <option name="selectedValue" label={item.Company_SubCategory_Text} value={item.ID} >{item.Company_SubCategory_Text}</option>
                                                                                          
                                                                                        ))}
                                                                                    </select> : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <div className="input-group">

                                                                                    <input placeholder='Firma Adı*' onChange={this.changeName} name="name" type="text" className="form-control" required />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <div className="form-group">
                                                                                <div className="input-group">

                                                                                    <input placeholder='Firma Telefon Numarası*' onChange={this.changeTel} name="tel" type="text" className="form-control" required />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <div className="form-group">
                                                                                <div className="input-group">

                                                                                    <input placeholder='Firma Email' onChange={this.changeEmail} name="email" type="text" className="form-control" required />
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <div className="input-group">

                                                                                    <textarea placeholder='Firma Açık Adres' onChange={this.changeAddress} name="address" type="text" className="form-control" required />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-md-12 col-lg-6">


                                                                            <button name="submit" type="submit" value="Submit" className="btn button-md"><i className="fa fa-location-arrow"></i> Haritadan Konum Ekle</button>





                                                                        </div>
                                                                       
                                                                        <input onClick={(e) => this.postRehber(e)} type="submit" defaultValue="Submit Comment" className="submit btn button-md" id="submit" name="submit" />

                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-5">
                                                        <aside className="sticky-top left-border-1" >
                                                            <div className="widget" >
                                                                <h6 className="widget-title">Önizleme</h6>
                                                                <h5>   {this.state.selectedValue}</h5>
                                                                <div className="feature-container feature-bx1" >

                                                                    <div className="icon-content">
                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                                                                            <div style={{ justifyContent: 'end', display: 'flex', width: '100%' }}>
                                                                                <h7 style={{ marginRight: 5 }}><i className='fa fa-thumbs-up'></i>0</h7>
                                                                                <h7 style={{ marginRight: 5 }}> <i className='fa fa-eye'></i>1</h7></div>
                                                                        </div>
                                                                        <h4 className="ttr-tilte" >{this.state.name}</h4>
                                                                        <p ><i className="fa fa-location-arrow"></i></p>
                                                                        <p ><i className="fa fa-mobile"></i>{this.state.tel} </p>

                                                                        <p ><i className="fa fa-envelope"></i> {this.state.email}</p>
                                                                        <p ><i className="fa fa-map-marker"></i>{this.state.address} </p>
                                                                    </div>
                                                                    <div className="bg-img" style={{ backgroundColor: '#5096C9' }}></div>
                                                                </div>
                                                            </div>


                                                            <div className="widget">
                                                                <h6 className="widget-title">Ödeme Özeti</h6>



                                                                <div className="cart-box">

                                                                    <div className="product-dec">
                                                                        <h6 className="title" style={{ color: 'red' }}>TOPLAM</h6>

                                                                        <span className="product-price" style={{ color: 'red' }}> €0</span>
                                                                    </div>

                                                                </div>
                                                            </div>


                                                        </aside>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>



                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                <Footer1 />

                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </>
        );
    }
}

export default ShopCheckout;