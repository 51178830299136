import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import ReactHtml from 'raw-html-react';
import Rehber from "../../images/gallery/birbilen.png"
import Logo from "../../images/gallery/logo.png"
import axios from "axios";
import base64 from 'react-native-base64';


const maincategoryUrl = "https://getjob.stechomeyazilim.info:5100/getMainCategory/select/";
const imgUrl = "https://berlinesnafadmin.stechomeyazilim.com";
class About1 extends Component{



	constructor() {
		super()
		this.state = {

			maincategoryArray:[]
		}
	}


	componentDidMount() {
	
		this.getMainCategory();
	}


	getMainCategory = async () => {
		try {
			axios.get(maincategoryUrl).then((res) => {
				console.log("res123", res.data)
				this.setState(
					{
						maincategoryArray: res.data,
					});
			});
		} catch (error) {
			console.log("error" + error);
		}
	};
	render(){
		return(
			<>
				{this.state.maincategoryArray.map(item => (

				<div className="section-area section-sp3 bg-white">
								<div className="container"></div>
				<div className="row">
					<div className="col-md-6">
						<div className="about-img-box3">
							<div className="img1">
								<img src={imgUrl + item.MainPage_Category_Image} alt=""/>
							</div>

							<div className="img2">
								<img src={Logo} alt=""/>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="heading-bx m-b20">
							<h6 className="title-ext m-b0">Berlin Esnaf</h6>
							<h2 className="title-head m-b0">{item.MainPage_Category_Title}</h2>
							<div className="ttr-separator sepimg"></div>
					
							<p><ReactHtml html={base64.decode(base64.decode(item.MainPage_Category_Main_Desc))} /></p>
													</div>
						<Link to="services-1" className="btn">{item.MainPage_Category_Title}</Link>
					</div>
				</div>
				</div>
					))}
			</>
			
		);
	}
}

export default About1;
