import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Elements


class Services extends Component{
	render(){
		return(
			<>
				<div className="section-area bg-gray section-sp2">
					<div className="container">
						<div className="heading-bx text-center">
							<h6 className="title-ext m-b0">Popüler Rehberler</h6>
						{/*	<h2 className="title-head m-b0">Excellent Service Provide <br/>For Business</h2>*/}
							<div className="ttr-separator sepimg"></div>
						</div>
						<div className="row">
							<div className="col-lg-4 col-md-6 col-sm-6 col-12 wow fadeIn" data-wow-delay="0.2s">
								<div className="feature-container feature-bx1">
									<div className="feature-lg text-white m-b20">
										<Link to="#" className="icon-cell"><i className="flaticon-fuel"></i></Link> 
									</div>
									<div className="icon-content">
										<h4 className="ttr-tilte">Gastronomi</h4>
										<p>catering, Döner prodüksiyon, Eis grosshandel.</p>
										<Link to="rehberdetay" className="btn-link">Daha Fazla</Link>
									</div>
									<div className="bg-img" style={{backgroundColor:'#5096C9'}}></div>
								</div>
							</div>
						
						
						</div>
					</div>


					<div class="col-lg-12 col-md-6 col-sm-6  text-center ">
                            <a style={{ color: 'black' }} class="btn btn-long d-sm-inline-block" href="/react/rehber-basvur">REHBERE BAŞVUR <i className="fa fa-plus"></i> </a>
                            </div>
				</div>
			</>
		);
	}
}

export default Services;
