import React from 'react';
import Markup from './markup/markup';

// bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

// slick-carousel 
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// React Modal Video
import 'react-modal-video/css/modal-video.min.css';

import "swiper/swiper-bundle.min.css";

// Fonts
import './vendors/fontawesome/css/font-awesome.min.css';
import './vendors/flaticon/flaticon.css';
import './vendors/flaticon/light/flaticon1.css';
import './vendors/line-awesome/css/line-awesome.css';
import './vendors/themify/themify-icons.css';

// Stylesheet
import './css/typography.css';
import './css/shortcodes/shortcodes.css';
import './css/style.css';
import './css/color/color-1.css';
import 'moment/locale/tr';
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";


function App() {
	return (
		<div className="App">
			<Markup />
			<WhatsAppWidget
			phoneNo="+905051187380"
			position="left"
			widgetWidth="300px"
			widgetWidthMobile="260px"
			autoOpen={false}
			autoOpenTimer={5000}
			messageBox={true}
			messageBoxTxt="Merhaba, bize ne sormak istersiniz?"
			iconSize="40"
			iconColor="white"
			iconBgColor="#4dc247"
			headerIcon="https://buhara.com/buharalogo.png"
			headerIconColor="#c5aa77"
			headerTxtColor="white"
			headerBgColor="#c5aa77"
			headerTitle="Berlin Esnaf"
			headerCaption="Online"
			bodyBgColor="#bbb"
			chatPersonName="Destek"
			chatMessage={<>Merhaba 👋 <br /><br /> Size Nasıl Yardımcı Olabilirim?</>}
			footerBgColor="#999"
			placeholder="Mesaj Türü.."
			btnBgColor="#0a4022"
			btnTxt="Konuşmaya Başla"btnTxtColor="white"
		
			/>
		</div>
	);
}

export default App;
