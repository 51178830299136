import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';

// Images
import Banner1 from '../../images/banner/banner1.jpg';

class Contact1 extends Component {
	
	render(){
		return (
			<>
				<Header1 />
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark parallax" style={{backgroundImage: "url("+Banner1+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">Trafik Durumu</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
										<li>Trafik Durumu</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					<div className="page-banner contact-page bg-white">
						<div className="container-fuild">
							<div className="row m-lr0" style={{margin:20}}>
								<div className="col-lg-6 col-md-6 p-lr0 d-flex" >
									<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3448.1298878182047!2d-81.38369578541523!3d30.204840081824198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e437ac927a996b%3A0x799695b1a2b970ab!2sNona+Blue+Modern+Tavern!5e0!3m2!1sen!2sin!4v1548177305546" className="align-self-stretch d-flex" style={{ width: "100%", minHeight: "100%"}} allowFullScreen></iframe>
								</div>
								<div className="col-lg-6 col-md-6 section-sp2 p-lr30">
                              
								<div className="ajax-message"></div>
								
										<div className="row placeani">
										
                                        <div className="col-md-12">
												<form className="ajax-form form-area-box get-in-touch" action="script/contact.php">
													<div className="ajax-message"></div>
													<div className="row placeani">
														
														<div className="col-lg-6">
															<div className="form-group">
																<div className="input-group"> 
																	<select name="quote-request-reach">
																		<option value="">NEREDEN</option>
																		<option value="09am-12pm">KONYA</option>
																		<option value="12pm-03pm">ANKARA</option>
																		<option value="03pm-06pm">İSTANBUL</option>
																	</select>
																</div>
															</div>
														</div>
														<div className="col-lg-6">
															<div className="form-group">
																<div className="input-group">
																	<select name="quote-request-hear">
																		<option value="">NEREYE</option>
																		<option value="Friends">İZMİR</option>
																		<option value="Facebook">SAMSUN</option>
																		<option value="Google">SİVAS</option>
																		<option value="Collegue">ANTALYA</option>
																		<option value="Others">HAKKARİ</option>
																	</select>
																</div>
															</div>
														</div>
														
                                                        <div className="col-lg-12">
												<button name="submit" type="submit" value="Submit" className="btn button-md" style={{width:'100%',margin:10,borderRadius:10,boxShadow: '2px 1px 9px gray',color:'black'}}>
                                                    <i className="ti-location-pin"></i>Sonuçları Listele</button>
                          
										
											</div>
													</div>
												</form>
											</div>
										
									
										</div>
									
								</div>
							</div>
						</div>
					</div>
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}

export default Contact1;