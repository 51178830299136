import React, { Component } from 'react';
import Header1 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';
import ServiceSlider1 from '../elements/services/service-slider-1';

import Slider from "react-slick";
import axios from "axios";
import BackBg1 from "../../images/background/bg1.png"
import BackBg2 from "../../images/background/bg2.png"
import BackBg4 from "../../images/background/bg4.jpg"
import AboutPic14 from "../../images/about/pic14.jpg"
import AboutPic15 from "../../images/about/pic15.jpg"
import SimpleReactLightbox, { SRLWrapper, useLightbox } from 'simple-react-lightbox';


import Banner1 from "../../images/banner/banner1.jpg"
import { Link } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Premium from '../../images/gallery/premium.png';

import ReactHtml from 'raw-html-react';
import base64 from 'react-native-base64';
import utf8 from "utf8"



const MagnificAnchor = props => {
	const { openLightbox } = useLightbox()

	return (
		<Link to={"#"} onClick={() => openLightbox(props.imageToOpen)} className="magnific-anchor right">
			View Image
		</Link>
	)
}

const options = {
	settings2: {
		overlayColor: "rgba(0,0,0,0.9)",
		backgroundColor: "#fe5a0e",
		slideAnimationType: 'slide',
	},
	buttons: {
		backgroundColor: "#fe5a0e",
		iconColor: "rgba(255, 255, 255, 1)",
		showAutoplayButton: false,
		showDownloadButton: false,
	},
	caption: {
		captionColor: "#a6cfa5",
		captionFontFamily: "Raleway, sans-serif",
		captionFontWeight: "300",
		captionTextTransform: "uppercase",
	}
}

const settings3 = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
};

const sliderUrl = "https://getjob.stechomeyazilim.info:5101/getSlider/select/";
const maincategoryUrl = "https://getjob.stechomeyazilim.info:5101/getMainCategory/select/";
const birbilenUrl = "https://getjob.stechomeyazilim.info:5101/getForum/select/";
const ilanUrl = "https://getjob.stechomeyazilim.info:5101/getJobAdvertisement/select/";
const rehberUrl = "https://getjob.stechomeyazilim.info:5101/getCompanyList/select/";
const aktuelUrl = "https://getjob.stechomeyazilim.info:5101/getNewsCategory/select";

const imgUrl = "https://berlinesnafadmin.stechomeyazilim.com/";
class Index extends Component {

	constructor() {
		super()
		this.state = {
			sliderArray: [],
			maincategoryArray: [],
			birbilenArray: [],
			ilanArray: [],
			rehberArray: [],
			rehberSubCatArray: [],
			aktuelArray: [],
			ilanimageArray: [],
		}
	}

	componentDidMount() {
		this.getSliders();
		this.getMainCategory();
		this.getBirbilen();
		this.getIlan();
		this.getRehber();
		this.getAktuel()
	}
	getSliders = async () => {
		try {
			axios.get(sliderUrl).then((res) => {
				console.log("res123", res.data)
				this.setState(
					{
						sliderArray: res.data,
					});
			});
		} catch (error) {
			console.log("error" + error);
		}
	};


	getAktuel = async () => {
		try {
			axios.get(aktuelUrl).then((res) => {
				console.log("res123", res.data)
				this.setState(
					{
						aktuelArray: res.data,
					});
			});
		} catch (error) {
			console.log("error" + error);
		}
	};

	getRehber = async () => {
		try {
			axios.get(rehberUrl).then((res) => {
				console.log("res123", res.data)
				this.setState(
					{
						rehberArray: res.data,
						rehberSubCatArray: res.data,
					});
			});
		} catch (error) {
			console.log("error" + error);
		}
	};

	getIlan = async () => {
		try {
			axios.get(ilanUrl).then((res) => {
				console.log("res123", res.data)
				this.setState(
					{
						ilanArray: res.data,
						ilanimageArray: res.data,
					});
			});
		} catch (error) {
			console.log("error" + error);
		}
	};

	getMainCategory = async () => {
		try {
			axios.get(maincategoryUrl).then((res) => {
				console.log("res123", res.data)
				this.setState(
					{
						maincategoryArray: res.data,
					});
			});
		} catch (error) {
			console.log("error" + error);
		}
	};
	getBirbilen = async () => {
		try {
			axios.get(birbilenUrl).then((res) => {
				console.log("res123", res.data)
				this.setState(
					{
						birbilenArray: res.data,
					});
			});
		} catch (error) {
			console.log("error" + error);
		}
	};

	render() {

		const settings2 = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
		};
		const settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 4,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1199,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 991,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		return (
			<>

				<Header1 />

				{/* <!-- Content --> */}
				<div className="page-content bg-gray">



					<Slider {...settings2} className="tt-slider slider-sp0 slider-two">

						{this.state.sliderArray.map(item => (

							<div className="slider-item">
								<div className=" ovbl-middle  h-1/2 w-full">
									<img className='w-full' src={imgUrl + item.Sliders_Image} alt="" />
								</div>
								<div className="slider-content text-center text-white">
									<div className="container">
										<div className="content-inner">
											<h6 className="sub-title">BERLİN ESNAF</h6>
											<h2 className="title">BERLİN <br />ESNAF</h2>
											<a className="btn primary" href="/contact-1">İletişim</a>
										</div>
									</div>
								</div>
							</div>
						))}
					</Slider>

					{/* <!-- Main Slider --> */}
					<div className="content-block" id="content-area">





						<div className="section-area process-area">
							<div className="container">
								{/*<Link to="#" className="project-btn"><i className="icon ti-home"></i> View All Project <i className="ti-arrow-right right-icon"></i></Link>*/}
								<ServiceSlider1 />
							</div>
						</div>


						<div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + Banner1 + ")" }}>
							<div className="container">
								<div className="page-banner-entry">
									<h1 className="text-white">Bu Alana Reklam Verebilirsiniz</h1>

								</div>
							</div>
						</div>

						<div className="section-area section-sp2 bg-gray" style={{ backgroundImage: "url(" + BackBg2 + ")", backgroundPosition: "bottom", backgroundSize: "100%" }}>
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-6 col-md-12 wow fadeIn m-md-b40" data-wow-delay="0.3s">
										<div className="heading-bx m-b30">
											<h6 className="title-ext m-b0">Berlin Esnafa Hoşgeldiniz</h6>
											<h2 className="title-head m-b0">Hakkımızda</h2>
											<div className="ttr-separator sepimg"></div>
											<p className="head-px2">Our purpose follow leading design, engineering and industrial manufacturing company specialising in mining, oil, gas and  engineering and industrial manufacturing company specialising in mining, oil, gas and engineering services.</p>
										</div>

										<Link to="about-1" className="btn-secondry">Hakkımızda</Link>
									</div>
									<div className="col-lg-6 col-md-12 wow fadeIn" data-wow-delay="0.6s">
										<div className="about-img-box4 row sp10">
											<div className="col-lg-12">
												<div className="about-img1">
													<img src={AboutPic14} alt="" />
												</div>
											</div>
											<div className="col-lg-12 m-b10 m-t10">
												<div className="about-img2">
													<img src={AboutPic15} alt="" />
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="section-area section-sp3 bg-black">
							<div className="container">
								<div className="row">
									<div className="col-lg-12">
										<div className="heading-bx text-center m-b0">
										
											<h2 style={{ color: 'white' }} className="title-head m-b0">Öne Çıkan Rehberler</h2>
											

										<Link style={{color:'white',padding:10,cursor:'pointer'}} to={ "/rehber"  } >	<h6 className="title-ext m-b0" style={{fontWeight:'bold'}}>Daha Fazlası</h6><i className='fa fa-mouse-pointer'></i></Link>
										
										</div>
									</div>
								</div>
							</div>

							<SimpleReactLightbox>
								<SRLWrapper options={options}>
									<Slider {...settings} className="slider-sp0 port-slider-7 arrow-none">
										{this.state.rehberArray.map(item => (
											<Link to={ "/rehberaltkategori/" + (item.Company_Category_Title).replace(/\s+/g, '-') }  state= {{ _item: item }} className="btn-link black radius-xl">

												<div className="col-lg-10 col-md-6 col-sm-6 col-12 wow fadeIn" data-wow-delay="0.2s">
													<div className="feature-container feature-bx1">
													
														<div className="icon-content">
															<h4 className="ttr-tilte">{item.Company_Category_Title}</h4>
															{item.Company_SubCategory.slice(0, 4).map(item2 => (
																<p>{item2.Company_SubCategory_Text}</p>))}


															<Link  to={ "/rehberaltkategori/" + (item.Company_Category_Title).replace(/\s+/g, '-') }  state= {{ _item: item }}className="btn-link">Daha Fazla</Link>
														</div>
														<div className="bg-img" style={{ backgroundColor: '#5096C9' }}></div>
													</div>
												</div>
											</Link>

										))}
									</Slider>
								</SRLWrapper>
							</SimpleReactLightbox>


						</div>

						<div className="section-area section-sp2 bg-primary" style={{ backgroundImage: "url(" + BackBg2 + ")", backgroundPosition: "bottom", backgroundSize: "100%" }}>
							<div className="container">
								<div className="row">
									<div className="col-lg-12">
										<div className="heading-bx text-center">
											<h2 style={{ color: 'black' }} className="title-head m-b0">Öne Çıkan İlanlar</h2>
											

											<Link style={{color:'black',padding:10,cursor:'pointer'}} to={"/ilan"  } >
													<h6 className="title-ext m-b0" style={{fontWeight:'bold',color:'black'}}>Daha Fazlası</h6><i style={{color:'white'}} className='fa fa-mouse-pointer'></i></Link>
											

									
										</div>
									</div>
								</div>

							</div>
						
							<SimpleReactLightbox>
								<SRLWrapper options={options} >

									<Slider {...settings} className="slider-sp0 port-slider-7 arrow-none">


										{this.state.ilanArray.slice(0, 5).map(item => (

											item.Job_Advertisement_Image.length == 0 ?

												<div className="col-lg-12 col-md-6 col-sm-6 col-12 wow fadeIn" data-wow-delay="0.2s">
										
													<div className="case-study-box">
												
														<div className="case-content" style={{ backgroundColor: 'white', padding: 0, paddingTop: 30, paddingBottom: 30 }}>
															<div style={{ display: 'flex', flexDirection: 'row' }}>
																<span style={{backgroundColor:'black'}} className="categary">
																	{item.Job_Advertisement_SubCategory.Job_Advertisement_SubCategory_Text} - 
																	{item.Job_Advertisement_SubCategory.Job_Advertisement_Category.Job_Advertisement_Category_Title}</span>

															</div>
															<h4 className="title" style={{ backgroundColor: item.Job_BackgroundColor, color: item.Job_TitleColor }} >
																<ReactHtml html={item.Job_Advertisement_Title} />
															</h4>

														</div>
														<Link
															to={"/ilan-detail/" + (item.ID) } state= {{ _item: item } }style={{ justifyContent: 'center', display: 'flex', color: '#EFBB20', fontWeight: 'bold', backgroundColor: 'black' }} className="btn">Detaya Git</Link>

													</div>






												</div>
												:
												<div className="col-lg-12 col-md-6 col-sm-6 col-12 wow fadeIn" data-wow-delay="0.2s">
													<div style={{ position: 'absolute', top: -15, right: 0, zIndex: 1 }}>
														<img style={{ width: 60 }} src={Premium} alt="" /></div>

													<div className="case-study-box"
														style={{ border: 'solid', borderColor: item.Job_BorderColor, borderWidth: item.Job_BorderWidth }}>

														<div className="case-media" >
															{item.Job_Advertisement_Image.slice(0, 1).map(item2 => (

																<img src={imgUrl+item2.Job_Advertisement_Image_Text} alt="" />

															))}
														</div>



														<div className="case-content" style={{ backgroundColor: 'white', padding: 0, paddingTop: 30, paddingBottom: 30 }}>
															<div style={{ display: 'flex', flexDirection: 'row' }}>
																<span style={{backgroundColor:'black'}}  className="categary">{item.Job_Advertisement_SubCategory.Job_Advertisement_SubCategory_Text} - {item.Job_Advertisement_SubCategory.Job_Advertisement_Category.Job_Advertisement_Category_Title}</span>



															</div>
															<h4 className="title" style={{ backgroundColor: item.Job_BackgroundColor, color: item.Job_TitleColor }} >
																<ReactHtml html={item.Job_Advertisement_Title} />
															</h4>

														</div>
														<Link
															to={"/ilan-detail/" + (item.ID) }   state= {{ _item: item } }style={{ justifyContent: 'center', display: 'flex', color: '#EFBB20', fontWeight: 'bold', backgroundColor: 'black' }} className="btn">Detaya Git</Link>

													</div>






												</div>



										))}



									</Slider>
								

								</SRLWrapper>
							</SimpleReactLightbox>
							
						</div>


						<div className="section-area overflow-hidden section-sp2 ovbl-dark" style={{ backgroundImage: "url(" + BackBg4 + ")", backgroundPosition: "center" }}>
							<div className="container">
								<div className="container">
									<div className="row">
										<div className="col-lg-12">
											<div className="heading-bx text-center m-b0">
											
												<h2 style={{ color: 'white' }} className="title-head m-b0">Öne Çıkan BirBilen</h2>
											

											<Link style={{color:'white',padding:10,cursor:'pointer'}} to={"/birbilen"  } >	<h6 className="title-ext m-b0" style={{fontWeight:'bold',color:'#FDCC03'}}>Daha Fazlası</h6><i style={{color:'white'}} className='fa fa-mouse-pointer'></i></Link>
											
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-12">
										<Slider {...settings3} className="arrow-none dots-style-2">
											{this.state.birbilenArray.slice(1, 4).map(item => (

												<Link to={"/birbilendetay/" + (item.Forum_Title).replace(/\s+/g, '-')  }  state={ { _item: item }} className="btn-link black radius-xl">
													<div className="slider-item">
														<div className="testimonial-bx style3" >
															<div className="testimonial-content" >
																<p style={{ color: '#EFBB20' }}>{item.Forum_Title}</p>

															</div>
															{item.Forum_Short_Desc != null ? <div className="testimonial-content" >

																<h5 style={{ color: 'white' }}><ReactHtml html={utf8.decode((item.Forum_Short_Desc))} /></h5>

															</div> : null}


															<div className="client-info">
																<div className="testimonial-info">
																	<h6 className="name">{item.Users.Users_Name_Surname}</h6>

																</div>
																<div className="testimonial-thumb radius-xl">
																	<img src={imgUrl + item.Users.Users_Profile_Photo} alt="" />
																</div>

															</div>

														</div>
													</div>
												</Link>
											))}

										</Slider>
									</div>
								</div>
							</div>
						</div>

						<div className="section-area section-sp1 bg-black" style={{ backgroundImage: "url(" + BackBg1 + ")", backgroundPosition: "bottom", backgroundSize: "100%" }}>
							<div className="container">
								<div className="row">
									<div className="col-lg-12">
										<div className="heading-bx text-black text-center">
										
							
											<h2 style={{ color: 'white' }} className="title-head m-b0">Öne Çıkan Aktüeller</h2>
											

											<Link style={{padding:10,cursor:'pointer'}} to={"/aktuel"} >	<h6 className="title-ext m-b0" style={{fontWeight:'bold',color:'#FDCC03'}}>Daha Fazlası</h6><i style={{color:'white'}} className='fa fa-mouse-pointer'></i></Link>
				
										</div>
									</div>
								</div>
								<Tabs className="history-box">
									<TabList className="nav nav-tabs">
										{this.state.aktuelArray.map(item => (
											<Tab style={{color:'white'}}>{item.News_Category_Title}</Tab>

										))}
									</TabList>
									{this.state.aktuelArray.map(item => (

										<TabPanel>
											<div className="row align-items-center text-white">
												<div className="col-xl-5 col-lg-6">
													<img src={imgUrl + item.News_Category_Image} alt="" />
												</div>
												<div className="col-xl-7 col-lg-6">
													<div className="history-info">
														<h3><ReactHtml html={base64.decode(base64.decode(item.News_Category_Html_Content))} /></h3>

													</div>
												</div>
											</div>

										</TabPanel>
									))}
								</Tabs>
							</div>
						</div>






					</div>

				</div>

				<Footer1 />
			</>
		);
	}
}

export default Index;