import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Layout
import Header1 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';
// Images

import Moment from 'moment';
import Banner4 from "../../images/banner/banner4.jpg"
import axios from "axios";
const rehberUrl = "https://getjob.stechomeyazilim.info:5101/getCompanyCategory/select/";

const rehberUrlpost = "https://getjob.stechomeyazilim.info:5101/postCompany/send"

class ShopCheckout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            tel: "",
            address: "",
            selectedValue: "",
            email: "",
            baslikbtnname: "",
            baslikbtnprice: 0,
            borderbtnname: "",
            borderbtnprice: 0,
            backgrndbtnname: "",
            backgrndbtnprice: 0,
            rehberKategoriArray: [],
            getImage: "",
            file: null


        };
        this.changeTitleColor = this.changeTitleColor.bind(this);
        this.changeBackgroundColor = this.changeBackgroundColor.bind(this);
        this.changeBorderColor = this.changeBorderColor.bind(this);
        this.uploadSingleFile = this.uploadSingleFile.bind(this)
    }

    uploadSingleFile(e) {
        let files = e.target.files;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);
        fileReader.onload = (event) => {

            console.log("hvhjvj12", event)
            this.setState({ filePreview: event.target.result })

            this.upload(event.target.result)
        }

    }


    upload = async (val) => {
        try {

            const formData = { image: val }
            let endpoint = "https://getjob.stechomeyazilim.info:5101/fileUploadBerlin/insert/";
            axios.post(endpoint, formData, {
            }).then(async (res) => {

                console.log("ksd123fk", res.data)

                this.setState({ file: res.data })


                // this.showToast('GetJob', "Başarıyla Görseller Yüklendi!", 'success')
            })


        }
        catch (error) {
            console.log("upload" + error)
        }
    }
    postRehber = async (e) => {
        e.preventDefault()
        try {

            console.log("lsdnf", this.state.selectedValue)
            var testDateUtc = Moment.utc(new Date());
            var localDate = Moment(testDateUtc).local();

            console.log("ls23dnf", this.state.file)

            let data = {
                Company_Name: this.state.name,
                Company_Image: this.state.file,
                Company_Adress: this.state.address,
                Company_Location_Lat: null,
                Company_Location_Lng: null,
                Users_ID: 20,
                Company_WebSite: null,
                Company_Service_Area: null,
                Company_Description: null,
                Company_Advertised: null,
                Company_Active: null,
                Company_Add_DateTime: localDate,
                Company_Advertisement_Users_Complated_ID: null,
                Company_SubCategory_ID: this.state.categoryID,
                Company_TelephoneNumber: this.state.tel,
                Company_BackgroundColor: this.state.backgrndbtnname,
                Company_BorderColor: this.state.borderbtnname,
                Company_TitleColor: this.state.baslikbtnname,
                Company_Telephone_Check: null,
                Company_Premium_Font: this.state.fontText,
                Company_BorderWidth: null,
                Company_MailAdress: this.state.email,

            }
            axios.post(rehberUrlpost, data).then(async (res) => {
                console.log('dsd', this.state.file)
            })
        }

        catch (error) {
            console.log("errorccAS" + error)
        }
    }






    componentDidMount() {

        this.getRehberKategori();

    }
    getRehberKategori = async () => {
        try {
            axios.get(rehberUrl).then((res) => {
                console.log("res123", res.data)
                this.setState(
                    {
                        rehberKategoriArray: res.data,

                    });
            });
        } catch (error) {
            console.log("error" + error);
        }
    };




    changeTitleColor(e) {

        this.setState({ baslikbtnname: e.target.name, baslikbtnprice: 20 });
    }


    changeBorderColor(e) {
        const id = e.target.name;
        console.log(id);


        this.setState({ borderbtnname: e.target.name, borderbtnprice: 20 });

    }
    changeBackgroundColor(e) {

        this.setState({ backgrndbtnname: e.target.name, backgrndbtnprice: 20 });
    }

    changeName = (e) => {
        this.setState({ name: e.target.value });
    }
    changeTel = (e) => {
        this.setState({ tel: e.target.value });
    }
    changeAddress = (e) => {
        this.setState({ address: e.target.value });
    }
    changeEmail = (e) => {
        this.setState({ email: e.target.value });
    }

    kategoriChange = (selectedOptions) => {
        this.setState({ selectedOptions });
    }

    handleChange = (selectedOptions) => {
        this.setState({ selectedOptions });
    }

    handleChangeSelected = (e) => {
        console.log("lnkfsdklf", e.target)

        var index = e.nativeEvent.target.selectedIndex;
        var text = e.nativeEvent.target[index].text;

        this.setState({
            categoryID: e.target.value,
            selectedValue: text
        })
    }

    FontChange2 = (e) => {
        this.setState({ fontText: e.target.value })
    }


    render() {
        let imgPreview;
        if (this.state.file) {
            imgPreview = <img style={{ width: 100, borderRadius: 50 }} src={this.state.filePreview} alt='' />;
        }



        const Fonts = [

            {
                id: 1,
                title: 'CerebriSans-MediumItalic',
            },
            {
                id: 2,
                title: 'CerebriSans-LightItalic',
            }
        ]
        const Colors = [
            {
                id: 1,
                title: 'red',
            },
            {
                id: 2,
                title: 'blue',
            },
            {
                id: 3,
                title: 'green',
            },
            {
                id: 4,
                title: 'orange',
            },
            {
                id: 5,
                title: 'pink',
            },
            {
                id: 6,
                title: 'purple',
            },
            {
                id: 7,
                title: 'yellow',
            },
            {
                id: 8,
                title: 'gray',
            },
            {
                id: 9,
                title: 'darkblue',
            },
            {
                id: 10,
                title: 'lightgreen',
            }, ,
            {
                id: 11,
                title: 'lightgray',
            },
            {
                id: 12,
                title: 'cyan',
            },
            {
                id: 13,
                title: 'lightblue',
            },
            {
                id: 14,
                title: 'darkorange',
            },
            {
                id: 15,
                title: 'white',
            },
            {
                id: 16,
                title: 'black',
            },
            {
                id: 17,
                title: 'brown',
            },
            {
                id: 18,
                title: 'chocolate',
            }
        ]


        return (
            <>
                <Header1 />

                {/* <!-- Content --> */}
                <div className="page-content bg-white">

                    <div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + Banner4 + ")" }}>
                        <div className="container">
                            <div className="page-banner-entry">
                                <h1 className="text-white">Rehbere Başvur</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
                                        <li>Rehbere Başvur</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block" id="content-area">

                        <div className="section-area section-sp1 bg-gray">
                            <div className="container">
                                <div className="row row-grid checkout-area">
                                    <div className="content-block" id="content-area">

                                        <div className="section-area bg-gray section-sp2">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-7">
                                                        <div className="heading-bx">
                                                            <h6 className="title-ext m-b0">Rehber</h6>
                                                            <h3 className="title-head m-b0">Rehber Başvurusu</h3>
                                                        </div>
                                                        <div className="row m-b30">
                                                            <div className="col-md-12">
                                                                <form className="ajax-form form-area-box get-in-touch" action="script/contact.php">
                                                                    <div className="ajax-message"></div>
                                                                    <div className="row placeani">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <div className="input-group">
                                                                                    <select onChange={(e) => this.handleChangeSelected(e)} name="quote-request-reach">
                                                                                        <option value="">Kategori Seçiniz</option>
                                                                                        {this.state.rehberKategoriArray.map(item => (
                                                                                            item.Company_SubCategory.map(item2 => (
                                                                                                <option name="selectedValue" label={item2.Company_SubCategory_Text} value={item2.ID}>{item2.Company_SubCategory_Text}</option>

                                                                                            ))

                                                                                        ))}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <div className="input-group">

                                                                                    <input placeholder='Firma Adı*' onChange={this.changeName} name="name" type="text" className="form-control" required />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <div className="input-group">

                                                                                    <textarea placeholder='Firma Açık Adres' onChange={this.changeAddress} name="phone" type="text" className="form-control" required />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-6">
                                                                            <div className="form-group">
                                                                                <div className="input-group">

                                                                                    <input placeholder='Firma Email' onChange={this.changeEmail} name="phone" type="text" className="form-control" required />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <div className="form-group">
                                                                                <div className="input-group">

                                                                                    <input placeholder='Firma Telefon Numarası*' onChange={this.changeTel} name="phone" type="text" className="form-control" required />
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="col-lg-12">
                                                                            <div className="row m-b20">

                                                                                <div className="col-md-12 col-lg-6" style={{ border: '1px solid #cccccc', borderRadius: 5 }}>

                                                                                    <h5>Başlık Rengi Seç</h5>
                                                                                    <div style={{
                                                                                        color: 'white', backgroundColor: 'black', position: 'absolute', top: -15, right: 0, borderRadius: 10, padding: 5
                                                                                    }}>20Є</div>

                                                                                    <div className=" m-b10" >

                                                                                        {Colors.map(item => (
                                                                                            <input type="button" onClick={this.changeTitleColor} style={{ backgroundColor: item.title, width: 30, height: 30, borderRadius: 15, margin: 5 }} name={item.title} />

                                                                                        ))}

                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-12 col-lg-6" style={{ border: '1px solid #cccccc', borderRadius: 5 }}>
                                                                                    <h5>Çerçeve Rengi Seç</h5>
                                                                                    <div id='border' style={{
                                                                                        color: 'white', backgroundColor: 'black', position: 'absolute', top: -15, right: 0, borderRadius: 10, padding: 5
                                                                                    }}>72Є</div>
                                                                                    <div className=" m-b10">

                                                                                        {Colors.map(item => (
                                                                                            <input type="button" onClick={this.changeBorderColor} style={{ backgroundColor: item.title, width: 30, height: 30, borderRadius: 15, margin: 5 }} name={item.title} />

                                                                                        ))}
                                                                                    </div>
                                                                                </div>




                                                                            </div>
                                                                        </div>


                                                                        <div className="col-md-12 col-lg-6" style={{ border: '1px solid #cccccc', borderRadius: 5 }}>
                                                                            <h5>Arkaplan Rengi Seç</h5>
                                                                            <div style={{
                                                                                color: 'white', backgroundColor: 'black', position: 'absolute', top: -15, right: 0, borderRadius: 10, padding: 5
                                                                            }}>24Є</div>
                                                                            <div className=" m-b10">
                                                                                {Colors.map(item => (
                                                                                    <input type="button" onClick={this.changeBackgroundColor} style={{ backgroundColor: item.title, width: 30, height: 30, borderRadius: 15, margin: 5 }} name={item.title} />

                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12 col-lg-6">
                                                                            <div className="form-group">
                                                                                <h5>Font Seç</h5>
                                                                                <div className="input-group">
                                                                                    <select onChange={(e) => this.FontChange2(e)} name="quote-request-reach">
                                                                                        <option value="">Font Seçin</option>
                                                                                        {Fonts.map(item => (
                                                                                            <option name="selectedValue2" value={item.title}>{item.title}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-group">
                                                                                <h5>Firma Logosu Ekleyiniz</h5>
                                                                                <input type="file" className="form-control" onChange={this.uploadSingleFile} />
                                                                                <button type="button" className="btn btn-primary btn-block" onClick={this.upload}>Upload</button>

                                                                            </div>
                                                                            <button name="submit" type="submit" value="Submit" className="btn button-md"><i className="fa fa-location-arrow"></i> Haritadan Konum Ekle</button>
                                                                            <form>





                                                                            </form >
                                                                        </div>


                                                                    </div>
                                                                </form>


                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-5">
                                                        <aside className="sticky-top left-border-1" >
                                                            <div className="widget" >
                                                                <h6 className="widget-title">Önizleme</h6>
                                                                <h5>   {this.state.selectedValue}</h5>

                                                                <div className="feature-container feature-bx1" style={{ border: '3px solid ', borderColor: this.state.borderbtnname, backgroundColor: this.state.backgrndbtnname }} >

                                                                    <div className="icon-content">
                                                                     {imgPreview}
                                                                        <h4 className="ttr-tilte" style={{ color: this.state.baslikbtnname }}>{this.state.name}</h4>
                                                                        <p style={{ color: this.state.baslikbtnname }}><i className="fa fa-location-arrow"></i></p>
                                                                        <p style={{ fontStyle: this.state.selectedValue2, color: this.state.baslikbtnname }}><i className="fa fa-mobile"></i> {this.state.tel}</p>

                                                                        <p style={{ color: this.state.baslikbtnname }}><i className="fa fa-envelope"></i> {this.state.email}</p>
                                                                        <p style={{ color: this.state.baslikbtnname }}><i className="fa fa-map-marker"></i> {this.state.address}</p>


                                                                    </div>
                                                                    <div className="bg-img" style={{ backgroundColor: '#5096C9' }}></div>
                                                                </div>
                                                            </div>


                                                            <div className="widget">
                                                                <h6 style={{ fontFamily: '' }} className="widget-title">Ödeme Özeti</h6>
                                                                <div className="cart-box">

                                                                    <div className="product-dec">
                                                                        <h6 className="title">Başlık Rengi</h6>

                                                                        <span className="product-price"> {this.state.baslikbtnprice}</span>
                                                                    </div>

                                                                </div>
                                                                <div className="cart-box">

                                                                    <div className="product-dec">
                                                                        <h6 className="title">Arka Plan Rengi</h6>

                                                                        <span className="product-price"> {this.state.backgrndbtnprice}</span>
                                                                    </div>

                                                                </div>
                                                                <div className="cart-box">

                                                                    <div className="product-dec">
                                                                        <h6 className="title">Çerçeve  Rengi</h6>

                                                                        <span className="product-price">{this.state.borderbtnprice}</span>
                                                                    </div>

                                                                </div>
                                                                <div className="cart-box">

                                                                    <div className="product-dec">
                                                                        <h6 className="title" style={{ color: 'red' }}>TOPLAM</h6>

                                                                        <span className="product-price" style={{ color: 'red' }}> {this.state.baslikbtnprice + this.state.borderbtnprice}</span>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className="widget">
                                                                <h6 className="widget-title">Ödeme Yöntemi</h6>
                                                                <ul className="contact-infolist">
                                                                    <li>

                                                                        <Link style={{ marginRight: 5 }} name="submit" type="submit" value="Submit" className="btn button-md"><i className="fa fa-mobile"></i> MOBİL ÖDEME</Link>
                                                                        <Link to="/odeme1" type="submit" value="Submit" className="btn button-md">KREDİ KARTI ÖDEME</Link>

                                                                        <input onClick={(e) => this.postRehber(e)} type="submit" defaultValue="Submit Comment" className="submit btn button-md" id="submit" name="submit" />


                                                                    </li>



                                                                </ul>
                                                            </div>
                                                        </aside>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>



                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                </div>

                <Footer1 />

            </>
        );
    }
}

export default ShopCheckout;