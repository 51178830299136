import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';

// Images
import Banner1 from '../../images/banner/banner1.jpg';
import Moment from 'moment'
import axios from "axios";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'devextreme/dist/css/dx.light.css';
import { HtmlEditor } from 'devextreme-react/html-editor';

import { withParamsAndNavigate } from "../../getParamsAndNavigate.js";

import {
    Toolbar,
    Item,
    TableContextMenu,
    MediaResizing
} from "devextreme-react/html-editor";

import { tabs } from './data.js';


const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = ["Arial", "Georgia", "Tahoma", "Times New Roman", "Verdana"];
const headerValues = [false, 1, 2, 3, 4, 5];

const markupDesc = `
<div>
    <h2>
        Berlin Esnaf İlan Detaylarını giriniz ..
    </h2> </div>
`;

const birbilenKonuUrlpost = "https://getjob.stechomeyazilim.info:5101/postForum/send";


class Contact1 extends Component {
	
	constructor(props) {
        super(props);
        this.state = {
          
            valueContentHtmlDesc: ''
        }

        this.tabs = tabs;
        this.multilineChanged = this.multilineChanged.bind(this);
        this.currentTabChanged = this.currentTabChanged.bind(this);
     
        this.valueChangedDesc = this.valueChangedDesc.bind(this);

    }


    valueChangedDesc(e) {
        this.setState({
            valueContentHtmlDesc: e.value,
        });
    }

	multilineChanged(e) {
        this.setState({
            isMultiline: e.value,
        });
    }

    currentTabChanged(e) {
        this.setState({
            currentTab: e.value,
        });
    }

	postBirbilen = async (e) => {
		e.preventDefault()
		try {

			var testDateUtc = Moment.utc(new Date());
			var localDate = Moment(testDateUtc).local();
			let data = {
				Forum_Title: this.state.title,
				Users_ID: 1,
				Forum_DateTime: localDate,
				Forum_Short_Desc:this.state.valueContentHtmlDesc,

			}
			axios.post(birbilenKonuUrlpost, data).then(async(res) => {
				this.showToast('BerlinEsnaf', "Birbilen konunuz başarıyla sisteme yüklenmiştir!", 'success')
			})
		}

		catch (error) {
			console.log("errorccAS" + error)
		}
	}

	changeTitle = (e) => {
        this.setState({ title: e.target.value });
    }
	showToast = (event, value, type) => {
        if (type == "false") {
            toast.error(value, {
                position: "bottom-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        } else {
            toast.success(value, {
                position: "bottom-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            const { params, navigate } = this.props;
            navigate('/birbilen')
        }
    }
	render(){
		return (
			<>
				<Header1 />
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark parallax" style={{backgroundImage: "url("+Banner1+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">KONU AÇ</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
										<li>Konu Aç</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					<div className="page-banner contact-page bg-white">
						<div className="container-fuild">
							<div className="row m-lr0">
							
								<div className="col-lg-12 col-md-6 section-sp2 p-lr30">
									<form className="form-area-box ajax-form mw700 m-auto" method="post">
									<div className="ajax-message"></div>
									


									
										<div className="row placeani">
											<div className="col-lg-12 ">
												<div className="form-group">
													<div className="input-group">

														<input className="form-control valid-character" onChange={ this.changeTitle} type="text" defaultValue="" name="title" placeholder="Forum Başlığı" id="author" />
														
													</div>
												</div>
											</div>
										
										
											<div className="col-lg-12">
												<div className="form-group">
													<div className="input-group">
													
													<HtmlEditor onValueChanged={this.valueChangedDesc} defaultValue={markupDesc} valueType="html">
                                                                                        <Toolbar multiline={true}>
                                                                                            <Item name="undo" />
                                                                                            <Item name="redo" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="size" acceptedValues={sizeValues} />
                                                                                            <Item name="font" acceptedValues={fontValues} />
                                                                                            <Item name="separator" />
                                                                                            <Item name="bold" />
                                                                                            <Item name="italic" />
                                                                                            <Item name="strike" />
                                                                                            <Item name="underline" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="alignLeft" />
                                                                                            <Item name="alignCenter" />
                                                                                            <Item name="alignRight" />
                                                                                            <Item name="alignJustify" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="orderedList" />
                                                                                            <Item name="bulletList" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="header" acceptedValues={headerValues} />
                                                                                            <Item name="separator" />
                                                                                            <Item name="color" />
                                                                                            <Item name="background" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="link" />
                                                                                            <Item name="image" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="clear" />
                                                                                            <Item name="codeBlock" />
                                                                                            <Item name="blockquote" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="insertTable" />
                                                                                            <Item name="deleteTable" />
                                                                                            <Item name="insertRowAbove" />
                                                                                            <Item name="insertRowBelow" />
                                                                                            <Item name="deleteRow" />
                                                                                            <Item name="insertColumnLeft" />
                                                                                            <Item name="insertColumnRight" />
                                                                                            <Item name="deleteColumn" />
                                                                                            <Item name="cellProperties" />
                                                                                            <Item name="tableProperties" />
                                                                                        </Toolbar>
                                                                                        <TableContextMenu enabled={true} />
                                                                                        <MediaResizing enabled={true} />

                                                                                    </HtmlEditor>	</div>
												</div>
											</div>
											<div className="col-lg-12">
									
												<input onClick={(e) => this.postBirbilen(e)} type="submit" defaultValue="Submit Comment" className="submit btn button-md" id="submit" name="submit" />


											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					
					<ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}

export default withParamsAndNavigate(Contact1);