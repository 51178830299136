import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header2 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';

import Banner1 from "../../images/banner/banner1.jpg"
import ReactHtml from 'raw-html-react';
import axios from "axios"
import base64 from 'react-native-base64'
import moment from 'moment'
import utf8 from "utf8"

const birbilenUrl = "https://getjob.stechomeyazilim.info:5101/getForum/select";

const imgUrl = "https://berlinesnafadmin.stechomeyazilim.com";
class BlogClassicSidebar extends Component {
    constructor() {
        super()
        this.state = {
            birbilenArray: [],
            isLoading: true

        }
    }


    componentDidMount() {
        this.getBirbilen();

    }

    getBirbilen = async () => {
        try {
            axios.get(birbilenUrl).then((res) => {
                console.log("res123", res.data)
                this.setState(
                    {
                        birbilenArray: res.data,
                        isLoading: false
                    });
            });
        } catch (error) {
            console.log("error" + error);
        }
    };
    render() {
        return (
            <>
                <Header2 />

                {/* <!-- Content --> */}
                <div className="page-content bg-white">


                    <div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + Banner1 + ")" }}>
                        <div className="container">
                            <div className="page-banner-entry">
                                <h1 className="text-white">Bir Bilen</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
                                        <li>Bir bilen</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block" id="content-area">

                        <div className="section-area section-sp2 bg-white">
                            <div className="container">

                                <a style={{
                                    backgroundColor: '#FDCC03', padding: 60,
                                    bottom: -40, position: 'fixed', zIndex: 9, right: -50, borderTopLeftRadius: 150, border: 'solid', borderColor: 'GRAY', borderWidth: 1, boxShadow: '5px 10px 8px 10px #888888'
                                }} href="/birbilenkonu">
                                    <p style={{ color: 'black', fontSize: 20, fontWeight: 'bold' }}>KONU AÇ <i style={{ color: 'black', fontSize: 20 }} className="fa fa-plus"></i><br />	</p>


                                </a>
                                <div className="row">



                                    {this.state.birbilenArray.map(item => (

                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 wow fadeIn" data-wow-delay="0.2s">
                                            <div className="feature-container feature-bx1 ">

                                                <div className="icon-content bg-gray" style={{padding:10}}>
                                                    <p style={{ color: 'black' }}>
                                                        <img style={{ border: '0.1px solid #FDCC03', width: 80, borderRadius: 40 }} src={imgUrl + item.Users.Users_Profile_Photo} />
                                                        {item.Users.Users_Name_Surname} soruyor <i className="fa fa-comment"></i>
                                                    </p>
                                                    <p style={{ color: 'black', fontSize: 20 }}>   <i className="fa fa-calendar"></i>
                                                        {moment(item.Forum_DateTime).format('lll')}   </p>
                                                    <hr
                                                        style={{
                                                            color: 'gray',
                                                            backgroundColor: 'gray',
                                                            height: 1
                                                        }}
                                                    />
                                        
                                                    <h4 style={{color:'#FDCC03'}} className="ttr-tilte">    {item.Forum_Title}</h4>

                                                    <p><ReactHtml html={utf8.decode((item.Forum_Short_Desc))} /></p>


                                                    <Link style={{color:'#FDCC03'}}  to={"/birbilendetay/" + (item.Forum_Title).replace(/\s+/g, '-')} state={{ _item: item }} className="btn-link">Daha Fazla</Link>
                                                </div>
                                                <div className="bg-img" style={{ backgroundColor: '#5096C9' }}></div>
                                            </div>
                                        </div>
                                    ))}

                                </div>




                            

                                <br />


                            </div>
                        </div>

                    </div>




                </div>

                <Footer1 />

            </>
        );
    }
}

export default BlogClassicSidebar;