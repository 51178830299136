import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import Cloudy from '../../../images/gallery/cloudy.png';
import Traffic from '../../../images/gallery/traffic.png';
import Game from '../../../images/gallery/game.png';
import Music from '../../../images/gallery/music.png';

class ServiceSlider1 extends Component {

	render() {



		return (
			<>

				<div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
				
					<div className="slider-item">
						<div className="process-box" style={{ boxShadow: '2px 1px 9px gray', margin: '1em' }}>
							<h5 className="title" style={{ color: 'black' }}><img src={Traffic} alt="" /> Trafik Durumu</h5>

							<Link to="/trafikdurumu" className="process-btn">Detay <i className="ti-arrow-right"></i></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="process-box" style={{ boxShadow: '2px 1px 9px gray', margin: '1em' }}>
							<h5 className="title" style={{ color: 'black' }}><img src={Game} alt="" /> Oyunlar</h5>

							<Link to="/oyunlar" className="process-btn">Detay<i className="ti-arrow-right"></i></Link>
						</div>
					</div>
					<div className="slider-item">
						<div className="process-box" style={{ boxShadow: '2px 1px 9px gray', margin: '1em' }}>
							<h5 className="title" style={{ color: 'black' }}><img src={Music} alt="" /> Müzik</h5>

							<Link to="/muzik" className="process-btn">Detay <i className="ti-arrow-right"></i></Link>
						</div>
					</div>
				</div>

			</>
		);
	}
}

export default ServiceSlider1;
