import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Card, Header, Button } from 'react-bootstrap';

// Layout
import Header2 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';
import axios from "axios";
// Elements
import moment from 'moment'
// Images
import Banner1 from '../../images/banner/banner1.jpg';
import base64 from 'react-native-base64';
import ReactHtml from 'raw-html-react';
import { withParamsAndNavigate } from "../../getParamsAndNavigate.js";
import utf8 from "utf8"

const imgUrl = "https://berlinesnafadmin.stechomeyazilim.com";


const birbilenUrlpost = "https://getjob.stechomeyazilim.info:5101/postForumComment/send";
const birbilenlikeUrlPost ="https://getjob.stechomeyazilim.info:5101/postForumLiked/send";
const birbilenCommentLikePost="https://getjob.stechomeyazilim.info:5101/postForumCommentLiked/send";


class ServicesDetails extends Component {
	constructor(props) {
		super(props)
		this.state = {

			list: []

		}
	}
	async componentDidMount() {
		await this._getList()


	}
	_getList = async () => {
		try {

			await axios.get(`https://getjob.stechomeyazilim.info:5101/getForumDetail/select/${this.props.location.state._item.ID}`)
				.then((res) => {
					this.setState({ list: res.data })
				})
		}
		catch (error) {
			console.log("errorccAS" + error)
		}
	}

	postBirbilen= async (e) => {
		e.preventDefault()
		try {

			var testDateUtc = moment.utc(new Date());
			var localDate = moment(testDateUtc).local();
			let data = {
				Forum_Comment_Text: this.state.commentText,
				Users_ID: 1,
				Forum_ID: this.props.location.state._item.ID,
				Forum_Comment_Created_DateTime: localDate,
			
			}
			axios.post(birbilenUrlpost, data).then(async(res) => {
				await this._getList()
			})
		}

		catch (error) {
			console.log("errorccAS" + error)
		}
	}

	_changeText= (e,type) => {
		if(type =="comment"){
			this.setState({ commentText: e.target.value });
		}
		if(type =="name"){
			this.setState({ nameText: e.target.value });
		}
    }

	
	_changeText= (e,type) => {
		if(type =="comment"){
			this.setState({ commentText: e.target.value });
		}
		if(type =="name"){
			this.setState({ nameText: e.target.value });
		}
    }


	postBirbilenLike= async (e) => {
		e.preventDefault()
		try {

			let data = {
			
				Forum_ID: this.props.location.state._item.ID,
				Users_ID:17,		
			
			}

			console.log("res123data",data)

			axios.post(birbilenlikeUrlPost, data).then(async(res) => {
				console.log("res123",res)
				await this._getList()
			})
		}

		catch (error) {
			console.log("errorccAS" + error)
		}
	}



	postBirbilenCommentLike= async (e,ID) => {
		e.preventDefault()
		try {

			let data = {
			
				Users_ID: 20,
				Forum_Comment_ID: ID		
			
			}

			console.log("res123data",data)

			axios.post(birbilenCommentLikePost, data).then(async(res) => {
				console.log("res123",res)
				await this._getList()
			})
		}

		catch (error) {
			console.log("errorccAS" + error)
		}
	}




	render() {
		return (
			<>
				<Header2 />

				{this.state.list.length > 0 ?
					<div className="page-content ">

						<div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + Banner1 + ")" }}>
							<div className="container">
								<div className="page-banner-entry">
									<h1 className="text-white">{this.props.location.state._item.Forum_Title}</h1>
									<div className="breadcrumb-row">
										<ul className="list-inline">
											<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
											<li>Bir Bilen Detay

											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>


						<div className="content-block" id="content-area">

							<div className="section-area section-sp2 bg-fix bg-white">
								<div className="container about-video">
									<div className="row">
										<div className="col-lg-12 col-md-7 service-textarea portfolio-content">
											<div className="heading-bx text-left m-b20">
												<h4 style={{color:'#FDCC03'}} className="comments-title">{this.state.list[0].Forum_Title}</h4>
											
											</div>
											<p></p>
											<ol className="comment-list">
												<li className="comment ">
													<div className="comment-body " style={{backgroundColor:'	#F5F5F5'}} >
														<div className="comment-author vcard "> <img   style={{border: '2px solid black'}}className="avatar photo" src=
															{imgUrl+this.state.list[0].Users.Users_Profile_Photo} alt="" />
															<cite  style={{color:'black'}} className="fn"> {this.state.list[0].Users.Users_Name_Surname} soruyor <i className="fa fa-comment"></i></cite>

															<span  className="says">says:</span> </div>

													
														<div className="comment-meta">	{moment(this.state.list[0].Forum_DateTime).format('lll')}</div>
														<p style={{color:'white'}}> <ReactHtml html={utf8.decode(this.state.list[0].Forum_Short_Desc)} /></p>
														<div style={{position: 'absolute', pointerEvents: 'initial', right: 3, bottom:10, flexDirection:'column',display:'flex',alignItems:'center'}}>
																		
														<button onClick={(e)=> this.postBirbilenLike(e)} style={{  padding: 5, width: 40, height: 40, borderRadius: 20,borderWidth:1,borderColor:'gray', justifyContent: 'center', alignContent: 'center', display: 'flex', backgroundColor: ' #25d200', boxShadow: '2px 1px 3px white' }} to="#" className="comment-reply-link">
															<i style={{ color: 'white', fontSize: 20 }} className='fa fa-thumbs-up'></i></button>
															<p style={{color:'black'}}>{this.state.list[0].Forum_Liked.length} beğeni</p>
															</div>
													</div>


												</li>
											</ol>
											{this.state.list[0].Forum_Comment.length != 0 ?
												<div className="clear" id="comment-list">
													<div className="comments-area" id="comments">
														<h2 className="comments-title">{this.state.list[0].Forum_Comment.length} Yorum</h2>
														<div className="clearfix">
															{/* <!-- comment list END --> */}
															<ol className="comment-list">

																{this.state.list[0].Forum_Comment.map(item2 => (
																	<li className="comment"><div className="comment-body">
																		<div className="comment-author vcard"> <img className="avatar photo" style={{border: '2px solid #FDCC03'}} src={imgUrl + item2.Users.Users_Profile_Photo} alt="" />
																			<cite className="fn">{item2.Users.Users_Name_Surname}</cite> <span className="says">says:</span> </div>
																		<div className="comment-meta"> 	{moment(item2.Forum_Comment_Created_DateTime).format('lll')}</div>
																		<p> {item2.Forum_Comment_Text}</p>


																		<div className="comment-meta"> <Link to="#">{item2.Users_Name_Surname}</Link> </div>
																		<div style={{position: 'absolute', pointerEvents: 'initial', right: 3, bottom: 10,  flexDirection:'column',display:'flex',alignItems:'center'}}>
																		<button onClick={(e)=> this.postBirbilenCommentLike(e,item2.ID)} style={{ padding: 5, width: 40, height: 40, borderRadius: 20, justifyContent: 'center', alignContent: 'center', display: 'flex', backgroundColor: ' red', boxShadow: '2px 1px 9px gray' }} className="comment-reply-link">
																			<i style={{ color: 'white', fontSize: 20 }} className='fa fa-thumbs-up'></i> </button>

		
																			<p>{item2.Forum_Comment_Liked.length} beğeni</p>
																			</div>
																	</div>
																	</li>

																))}

																{/* <!-- list END --> */}



															</ol>
															<div className="comment-respond " style={{ backgroundColor: 'black' }} id="respond">
																<h4 className="comment-reply-title" style={{ color: '#FDCC03' }} id="reply-title">Yorum Yap <small> <Link style={{ display: "none" }} to="#" id="cancel-comment-reply-link" rel="nofollow">Cancel reply</Link> </small> </h4>
																<div className="comment-form" id="commentform" >
	
																	<p className="comment-form-comment">
																		<label htmlFor="comment">Yorumunuz</label>
																		<textarea onChange={(e)=> this._changeText(e,'comment')} rows="8" name="comment" placeholder="Yorum" id="comment"></textarea>
																	</p>
																	<p className="form-submit" style={{ justifyContent: 'center', display: 'flex' }}>
																		<input onClick={(e)=> this.postBirbilen(e)}  type="submit" defaultValue="Submit Comment" className="submit" id="submit" name="submit" />
																	</p>
																</div>
															</div>
															{/* <!-- comment list END --> */}
															{/* <!-- Form --> */}

															{/* <!-- Form --> */}
														</div>


													</div>



												</div>
												:

												<div className="clear" id="comment-list">
													<div className="comments-area" id="comments">

													<div className="comment-respond " style={{ backgroundColor: 'black' }} id="respond">
																<h4 className="comment-reply-title" style={{ color: '#FDCC03' }} id="reply-title">Yorum Yap <small> <Link style={{ display: "none" }} to="#" id="cancel-comment-reply-link" rel="nofollow">Cancel reply</Link> </small> </h4>
																<div className="comment-form" id="commentform" >
																	<p className="comment-form-comment">
																		<label htmlFor="author">İsminiz <span className="required">*</span></label>
																		<input onChange={(e)=> this._changeText(e,'name')} type="text" defaultValue="" name="İsminiz" placeholder="İsminiz" id="author" />
																	</p>

																	<p className="comment-form-comment">
																		<label htmlFor="comment">Yorumunuz</label>
																		<textarea onChange={(e)=> this._changeText(e,'comment')} rows="8" name="comment" placeholder="Yorum" id="comment"></textarea>
																	</p>
																	<p className="form-submit" style={{ justifyContent: 'center', display: 'flex' }}>
																		<input onClick={(e)=> this.postBirbilen(e)}  type="submit" defaultValue="Submit Comment" className="submit" id="submit" name="submit" />
																	</p>
																</div>
															</div>
													</div>



												</div>}



										</div>


									</div>
								</div>



							</div>



						</div>

					</div>
					: null}
				<Footer1 />

			</>
		);
	}
}
export default withParamsAndNavigate(ServicesDetails);