import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Card, Header, Button } from 'react-bootstrap';

// Layout
import Header2 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';

// Elements
import SolutionSidebar from '../elements/services/solution-sidebar';

// Images
import Banner1 from '../../images/banner/banner1.jpg';
import ServicesPic1 from '../../images/services/pic1.jpg';
import ServicesPic2 from '../../images/services/pic2.jpg';
import TestimonialsPic1 from "../../images/testimonials/pic1.jpg"
import TestimonialsPic2 from "../../images/testimonials/pic2.jpg"
import TestimonialsPic3 from "../../images/testimonials/pic3.jpg"



class ServicesDetails extends Component {

	render() {
		return (
			<>
				<Header2 />

				{/* <!-- Content --> */}
				<div className="page-content bg-white">

					<div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + Banner1 + ")" }}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">Aktüel Detay</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
										<li>Aktüel Detay</li>
									</ul>
								</div>
							</div>
						</div>
					</div>


					<div className="content-block" id="content-area">

						<div className="section-area section-sp2 bg-fix bg-white">
							<div className="container about-video">
								<div className="row">
							
									<div className="col-lg-9 col-md-7 service-textarea portfolio-content">
										<div className="heading-bx text-left m-b20">
											<h3 className="title-head m-b0">Agricultural Engineering</h3>



											<button style={{}} id="quik-search-btn" type="button" className="btn-link"><i className="ti-thumb-up"></i></button>

											<h7>0 beğeni</h7>





										</div>
										<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
										<div className="row">
											<div className="col-md-12 m-b15">
												<img src={ServicesPic1} className="radius-sm imghight" alt="" />
											</div>
										</div>





										<div className="clear" id="comment-list">
											<div className="comments-area" id="comments">
												<h2 className="comments-title">8 Comments</h2>
												<div className="clearfix">
													{/* <!-- comment list END --> */}
													<ol className="comment-list">
														<li className="comment">
															<div className="comment-body">
																<div className="comment-author vcard"> <img className="avatar photo" src={TestimonialsPic1} alt="" /> <cite className="fn">John Doe</cite> <span className="says">says:</span> </div>
																<div className="comment-meta"> <Link to="#">December 02, 2019 at 10:45 am</Link> </div>
																<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neqnsectetur adipiscing elit. Nam viae neqnsectetur adipiscing elit.
																	Nam vitae neque vitae sapien malesuada aliquet. </p>
																<div className="reply"> <Link to="#" className="comment-reply-link">Reply</Link> </div>
															</div>
															<ol className="children">
																<li className="comment odd parent">
																	<div className="comment-body">
																		<div className="comment-author vcard"> <img className="avatar photo" src={TestimonialsPic2} alt="" /> <cite className="fn">John Doe</cite> <span className="says">says:</span> </div>
																		<div className="comment-meta"> <Link to="#">December 02, 2019 at 10:45 am</Link> </div>
																		<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet.
																			In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis,
																			ac elementum ligula blandit ac.</p>
																		<div className="reply"> <Link to="#" className="comment-reply-link">Reply</Link> </div>
																	</div>
																	<ol className="children">
																		<li className="comment odd parent">
																			<div className="comment-body">
																				<div className="comment-author vcard"> <img className="avatar photo" src={TestimonialsPic3} alt="" /> <cite className="fn">John Doe</cite> <span className="says">says:</span> </div>
																				<div className="comment-meta"> <Link to="#">December 02, 2019 at 10:45 am</Link> </div>
																				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet.
																					In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis,
																					ac elementum ligula blandit ac.</p>
																				<div className="reply"> <Link to="#" className="comment-reply-link">Reply</Link> </div>
																			</div>
																		</li>
																	</ol>
																	{/* <!-- list END --> */}
																</li>
															</ol>
															{/* <!-- list END --> */}
														</li>
														<li className="comment">
															<div className="comment-body">
																<div className="comment-author vcard"> <img className="avatar photo" src={TestimonialsPic1} alt="" /> <cite className="fn">John Doe</cite> <span className="says">says:</span> </div>
																<div className="comment-meta"> <Link to="#">December 02, 2019 at 10:45 am</Link> </div>
																<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet.
																	In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis,
																	ac elementum ligula blandit ac.</p>
																<div className="reply"> <Link to="#" className="comment-reply-link">Reply</Link> </div>
															</div>
														</li>
														<li className="comment">
															<div className="comment-body">
																<div className="comment-author vcard"> <img className="avatar photo" src={TestimonialsPic2} alt="" /> <cite className="fn">John Doe</cite> <span className="says">says:</span> </div>
																<div className="comment-meta"> <Link to="#">December 02, 2019 at 10:45 am</Link> </div>
																<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet.
																	In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis,
																	ac elementum ligula blandit ac.</p>
																<div className="reply"> <Link to="#" className="comment-reply-link">Reply</Link> </div>
															</div>
														</li>
														<li className="comment">
															<div className="comment-body">
																<div className="comment-author vcard"> <img className="avatar photo" src={TestimonialsPic3} alt="" /> <cite className="fn">John Doe</cite> <span className="says">says:</span> </div>
																<div className="comment-meta"> <Link to="#">December 02, 2019 at 10:45 am</Link> </div>
																<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae neque vitae sapien malesuada aliquet.
																	In viverra dictum justo in vehicula. Fusce et massa eu ante ornare molestie. Sed vestibulum sem felis,
																	ac elementum ligula blandit ac.</p>
																<div className="reply"> <Link to="#" className="comment-reply-link">Reply</Link> </div>
															</div>
														</li>
													</ol>
													{/* <!-- comment list END --> */}
													{/* <!-- Form --> */}
													<div className="comment-respond" id="respond">
														<h4 className="comment-reply-title" id="reply-title">Yorum Yap <small> <Link style={{ display: "none" }} to="#" id="cancel-comment-reply-link" rel="nofollow">Cancel reply</Link> </small> </h4>
														<form className="comment-form" id="commentform" method="post">
															<p className="comment-form-comment">
																<label htmlFor="author">İsminiz <span className="required">*</span></label>
																<input type="text" defaultValue="" name="İsminiz" placeholder="İsminiz" id="author" />
															</p>

															<p className="comment-form-comment">
																<label htmlFor="comment">Yorumunuz</label>
																<textarea rows="8" name="comment" placeholder="Yorum" id="comment"></textarea>
															</p>
															<p className="form-submit">
																<input type="submit" defaultValue="Submit Comment" className="submit" id="submit" name="submit" />
															</p>
														</form>
													</div>
													{/* <!-- Form --> */}
												</div>
											</div>


											
										</div>
									</div>

									<div className="col-lg-3  widget recent-posts-entry">
										<h6 className="widget-title">Son AKTÜELLER</h6>
										<div className="widget-post-bx">
											<div className="widget-post clearfix">
												<div className="ttr-post-media"> <img src={TestimonialsPic1} width="200" height="143" alt="" /> </div>
												<div className="ttr-post-info">
													<div className="ttr-post-header">
														<h6 className="post-title"><Link to="blog-details-sidebar">Precious Tips To Help You Get Better.</Link></h6>
													</div>
													<ul className="media-post">
														<li><Link to="#">Oct 23 2019</Link></li>
													</ul>
												</div>
											</div>
											<div className="widget-post clearfix">
												<div className="ttr-post-media"> <img src={TestimonialsPic1} width="200" height="160" alt="" /> </div>
												<div className="ttr-post-info">
													<div className="ttr-post-header">
														<h6 className="post-title"><Link to="blog-details-sidebar">Ten Doubts You Should Clarify About.</Link></h6>
													</div>
													<ul className="media-post">
														<li><Link to="#">May 14 2019</Link></li>
													</ul>
												</div>
											</div>
											<div className="widget-post clearfix">
												<div className="ttr-post-media"> <img src={TestimonialsPic1} width="200" height="160" alt="" /> </div>
												<div className="ttr-post-info">
													<div className="ttr-post-header">
														<h6 className="post-title"><Link to="blog-details-sidebar">The 10 Steps Needed For Putting.</Link></h6>
													</div>
													<ul className="media-post">
														<li><Link to="#">June 12 2019</Link></li>
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>



						</div>



					</div>

				</div>

				<Footer1 />

			</>
		);
	}
}

export default ServicesDetails;