import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';

// Elements

// Images
import Banner1 from '../../images/banner/banner1.jpg';
import Sezen from '../../images/sezen.jpeg';
import Ahmet from '../../images/ahmet.jpeg';


class CompanyHistory extends Component {

    render() {
        return (
            <>
                <Header1 />

                {/* <!-- Content --> */}
                <div className="page-content bg-white">

                    <div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + Banner1 + ")" }}>
                        <div className="container">
                            <div className="page-banner-entry">
                                <h1 className="text-white">Müzik</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
                                        <li>Müzik</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block" id="content-area">

                        <div className="section-area section-sp2 bg-gray">
                            <div className="container">
                            <div className="search-bx style-1 heading-bx  " >
											<form role="search" method="post">
												<div className="input-group">
													<input name="text" className="form-control" placeholder="müzik ara" type="text" />
													<span className="input-group-btn">
														<button type="submit" className="fa fa-search text-primary"></button>
													</span>
												</div>
											</form>

										</div>
                                <div className="row">
                                    <div className="col-md-4 m-b30 ">
                                        <div className="award-bx bx-hover-up ">
                                            <div className="award-media"><img style={{opacity:0.6}}src={Ahmet} alt="" /></div>
                                            <div className="award-info">
                                                <h5 className="title"><i className="ti-music"></i> Söyle</h5>
                                                <p>Ahmet KAYA</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 m-b30">
                                        <div className="award-bx bx-hover-up">
                                            <div className="award-media">
                                                <img style={{opacity:0.6}} src={Sezen} alt="" />



                                            </div>
                                            <div className="award-info">
                                                <h5 className="title"><i className="ti-music"></i> Kutlama</h5>
                                                <p>Sezen AKSU</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 m-b30">
                                        <div className="award-bx bx-hover-up">
                                            <div className="award-media"><img style={{opacity:0.6}}src={Sezen} alt="" /></div>
                                            <div className="award-info">
                                                <h5 className="title"><i className="ti-music"></i> Sen Ağlama</h5>
                                                <p>Sezen AKSU</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>



                    </div>

                </div>

                <Footer1 />

            </>
        );
    }
}

export default CompanyHistory;