import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header1';
import Footer1 from '../layout/footer/footer1';

// Elements
import Services from '../elements/services/services';
import MissionVision from '../elements/mission-vision/mission-vision';
import Appointment from '../elements/appointment/appointment';
import ClientLogo from '../elements/client-logo/client-logo-1';

// Images
import Banner1 from '../../images/banner/banner1.jpg';
import ServicesPic1 from '../../images/our-services/pic1.jpg';
import ServicesPic2 from '../../images/our-services/pic2.jpg';
import ServicesPic3 from '../../images/our-services/pic3.jpg';
import ServicesPic4 from '../../images/our-services/pic4.jpg';
import Header2 from '../layout/header/header2';

class Services1 extends Component {
	
	render(){
		return (
			<>
				<Header2/>
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark parallax" style={{backgroundImage: "url("+Banner1+")"}}>
						<div className="container">
							
							<div className="page-banner-entry">
								<h1 className="text-white">Rehber</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
										<li>Rehber</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					<div className="content-block" id="content-area">
						
						<Services />
						
				
					
						
					</div>
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}

export default Services1;