import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import LogoWhite from "../../../images/logo-white.png"
import IconImg1 from "../../../images/icon/contact/icon1.png"
import IconImg2 from "../../../images/icon/contact/icon2.png"
import IconImg3 from "../../../images/icon/contact/icon3.png"

class Footer1 extends Component{
	render(){
		return(
			<>
				{/* <!-- Footer ==== --> */}
				<footer className="footer-style2">
					<div className="footer-top bt0">
						<div className="container">
							<div className="row align-items-center footer-info">
								<div className="col-lg-3 col-md-6 col-sm-6 m-md-b30">
									<div className="feature-container left footer-info-bx">
										<div className="feature-lg text-white">
											<span className="icon-cell"><img src={IconImg3} alt=""/></span> 
										</div>
										<div className="icon-content">
											<h5 className="ttr-tilte">Adresimiz</h5>
											<p>BERLİN</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 m-md-b30">
									<div className="feature-container left footer-info-bx">
										<div className="feature-lg text-white">
											<span className="icon-cell"><img src={IconImg1} alt=""/></span> 
										</div>
										<div className="icon-content">
											<h5 className="ttr-tilte">İletişim Numaramız</h5>
											<p>+001 123 456 790 <br/>(02) 3424 44 00</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 m-sm-b30">
									<div className="feature-container left footer-info-bx">
										<div className="feature-lg text-white">
											<span className="icon-cell"><img src={IconImg2} alt=""/></span> 
										</div>
										<div className="icon-content">
											<h5 className="ttr-tilte">Email Adresimiz</h5>
											<p>berlinesnaf@yourdomain.com</p>
										</div>
									</div>
								</div>
								<div className="col-lg-3 col-md-6 col-sm-6 text-left text-lg-right">
									<Link to="contact-1" className="btn btn-long d-sm-inline-block">Bizimle iletişim kurun</Link>
								</div>
							</div>
						</div>
						<div className="container">
							<div className="row">
								<div className="col-12 col-lg-3 col-md-5 col-sm-6">
									<div className="widget footer_widget">
											{/*<h5 className="footer-title">Our Solutions</h5>*/}
										<ul>
											<li><Link to="services-1"><span>Rehber</span></Link></li>
											<li><Link to="aktuel"><span> Aktüel</span></Link></li>
											<li><Link to="birbilen"><span>Bir Bilen</span></Link></li>
											<li><Link to="ilan"><span>İlan</span></Link></li>
												</ul>
									</div>
								</div>
								<div className="col-12 col-lg-4 col-md-7 col-sm-6">
									<div className="widget list-2">
										{/*<h5 className="footer-title">Company</h5>*/}
										<ul>
											<li><Link to="index">Anasayfa</Link></li>
											<li><Link to="company-history">Hakkımızda</Link></li>
											<li><Link to="contact-1">İletişim</Link></li>
								
										</ul>
									</div>
								</div>
								<div className="col-12 col-lg-5 col-md-12 col-sm-12">
									<div className="widget widget_info">
										<h5 className="footer-title">BERLİN ESNAF</h5>
									
										<form className="subscribe-form m-b20" action="assets/script/mailchamp.php" method="post">
											<div className="ajax-message"></div>
											<div className="input-group">
												<input name="email" required="required" className="form-control" placeholder="Email Adres" type="email"/>
												<div className="input-group-append">
													<button name="submit" value="Submit" type="submit" className="btn btn-block radius-sm">Abone Ol</button>	
												</div>
											</div>
										</form>
										<ul className="list-inline ft-social-bx">
											<li><Link to="#" className="btn button-sm"><i className="fa fa-facebook"></i></Link></li>
											<li><Link to="#" className="btn button-sm"><i className="fa fa-twitter"></i></Link></li>
											<li><Link to="#" className="btn button-sm"><i className="fa fa-linkedin"></i></Link></li>
											<li><Link to="#" className="btn button-sm"><i className="fa fa-google-plus"></i></Link></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="footer-bottom">
						<div className="container">
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-left">
									<p className="m-b0">Copyright © 2023 
									
								Stechome Software All Rights Reserved.</p>
								</div>
							
							
								<div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-right">
									<ul className="link-inline">
										<li><Link to="/">Anasayfa</Link></li>
									
									</ul>
								</div>
							</div>
						</div>
					</div>
				</footer>
			</>
		);
	}
}

export default Footer1;
