import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout

import Footer1 from '../layout/footer/footer1';

// Images
import Banner1 from '../../images/banner/banner1.jpg';

import Header2 from '../layout/header/header2';
import axios from "axios";
import { withParamsAndNavigate } from "../../getParamsAndNavigate.js";
const rehberUrl = "https://getjob.stechomeyazilim.info:5101/getCompanyList/select/";
class Services1 extends Component {
	constructor() {
		super()
		this.state = {
			
			rehberArray: [],
			rehberSubCatArray: [],
		
		}
	}

	componentDidMount() {
	
		this.getRehber();

	}
	getRehber = async () => {
		try {
			axios.get(rehberUrl).then((res) => {
				console.log("res123", res.data)
				this.setState(
					{
						rehberArray: res.data,
					});
			});
		} catch (error) {
			console.log("error" + error);
		}
	};


	render(){
		return (
			<>
				<Header2/>
				
				{/* <!-- Content --> */}
				<div className="page-content " >
					
					<div className="page-banner ovbl-dark parallax" style={{backgroundImage: "url("+Banner1+")"}}>
						<div className="container">
							
							<div className="page-banner-entry">
								<h1 className="text-white">{this.props.location.state._item.Company_Category_Title}</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
										<li>Rehber</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					<div className="content-block" id="content-area">
						
					<div className="section-area bg-gray section-sp2">
					<a style={{
								backgroundColor: '#FDCC03', padding: 60,
								bottom: -40, position: 'fixed', zIndex: 9, right: -50, borderTopLeftRadius: 150, border: 'solid', borderColor: 'GRAY', borderWidth: 1, boxShadow: '5px 10px 8px 10px #888888'
							}} href="/rehber-basvur">
								<p style={{ color: 'black', fontSize: 20, fontWeight: 'bold' }}>REHBERE BAŞVUR <i style={{ color: 'black', fontSize: 20 }} className="fa fa-plus"></i><br />	</p>


							</a>
					<div className="container">
						<div className="heading-bx text-center">

							<h6 className="title-ext m-b0">Rehber Alt Kategorileri</h6>
						{/*	<h2 className="title-head m-b0">Excellent Service Provide <br/>For Business</h2>*/}
							<div className="ttr-separator sepimg"></div>
                            
						</div>
						<div className="row">



                        {this.props.location.state._item.Company_SubCategory.slice(0,9).map(item2 => (



							<div className="col-lg-4 col-md-6 col-sm-6 col-12 wow fadeIn" data-wow-delay="0.2s">
								<div className="feature-container feature-bx1">
							
									<div className="icon-content">
										<h4 className="ttr-tilte">{item2.Company_SubCategory_Text}</h4>

									
										</div>
                                        <Link to={"/rehberdetay/" + (item2.Company_SubCategory_Text).replace(/\s+/g, '-') }  state={ { _item2: item2 }} className="btn-link">Daha Fazla</Link>
								
								</div>
							</div>
							))}
						
						</div>
					</div>
                  

				</div>
						
				
					
						
					</div>
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}

export default withParamsAndNavigate(Services1);