import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { render } from "react-dom";
// Layout
import Header1 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';
// Images
import Banner4 from "../../images/banner/banner4.jpg";
import Moment from 'moment';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'devextreme/dist/css/dx.light.css';
import { HtmlEditor } from 'devextreme-react/html-editor';

import {
    Toolbar,
    Item,
    TableContextMenu,
    MediaResizing
} from "devextreme-react/html-editor";

import { tabs } from './data.js';


const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = ["Arial", "Georgia", "Tahoma", "Times New Roman", "Verdana"];
const headerValues = [false, 1, 2, 3, 4, 5];

const ilanUrl = "https://getjob.stechomeyazilim.info:5101/getJobAdvertisementCategory/select/";
const ilanUrlPost = "https://getjob.stechomeyazilim.info:5101/postJobAdvert/send";


const markup = `
    <div>
        <h2>
            Berlin Esnaf İlan Başlığını giriniz ..
        </h2> </div>
`;
const markupDesc = `
<div>
    <h2>
        Berlin Esnaf İlan Detaylarını giriniz ..
    </h2> </div>
`;

class ShopCheckout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
       
            selectedValue: "",
            ilanKategoriArray: [],
            ilanSubArray: [],
            valueContentHtmlTitle: '',
            valueContentHtmlDesc: ''
        }

        this.tabs = tabs;
        this.multilineChanged = this.multilineChanged.bind(this);
        this.currentTabChanged = this.currentTabChanged.bind(this);
        this.valueChangedTitle = this.valueChangedTitle.bind(this);
        this.valueChangedDesc = this.valueChangedDesc.bind(this);

    }

    valueChangedDesc(e) {
        this.setState({
            valueContentHtmlDesc: e.value,
        });
    }
    valueChangedTitle(e) {
        this.setState({
            valueContentHtmlTitle: e.value,
        });
    }
    multilineChanged(e) {
        this.setState({
            isMultiline: e.value,
        });
    }

    currentTabChanged(e) {
        this.setState({
            currentTab: e.value,
        });
    }

    postIlan = async (e) => {
        e.preventDefault()
        try {

            console.log("lsdnf", this.state.selectedValue)
            var testDateUtc = Moment.utc(new Date());
            var localDate = Moment(testDateUtc).local();
            let data = {
                Job_Advertisement_Desc: this.state.valueContentHtmlDesc,
                Job_Advertisement_DateTime: localDate,
                Users_ID: 20,
                Job_Advertisement_Type_ID: 2,

                Job_Advertisement_Address: null,
                Job_Advertisement_Lat: null,
                Job_Advertisement_Lng: null,
                Job_Advertisement_SubCategory_ID: this.state.categorySubID,
                Job_Advertisement_Title: this.state.valueContentHtmlTitle,
                Job_Advertisement_Video: null
            }
            axios.post(ilanUrlPost, data).then(async (res) => {
                this.showToast('BerlinEsnaf', "İlanınız başarıyla sisteme yüklenmiştir!", 'success')
            })
        }

        catch (error) {
            console.log("errorccAS" + error)
        }
    }


    componentDidMount() {

        this.getIlanKategori();



    }

    getIlanKategori = async () => {
        try {
            axios.get(ilanUrl).then((res) => {
                console.log("res123", res.data)
                this.setState(
                    {
                        ilanKategoriArray: res.data,

                    });
            });
        } catch (error) {
            console.log("error" + error);
        }
    };

  

    handleChange = (selectedOptions) => {
        this.setState({ selectedOptions });
    }

    handleChangeSelectedSubCategory = (e) => {
        console.log("lnkfsdklf", e.target)

        var index = e.nativeEvent.target.selectedIndex;
        var text = e.nativeEvent.target[index].text;

        this.setState({
            categorySubID: e.target.value,
            selectedSubValue: text
        })
    }

    handleChangeSelected = (e) => {
        var index = e.nativeEvent.target.selectedIndex;
        var text = e.nativeEvent.target[index].text;

        let subCategory = this.state.ilanKategoriArray.filter((x) => x.Job_Advertisement_Category_Title == e.nativeEvent.target[index].text)

        this.setState({
            ilanSubArray: subCategory[0].Job_Advertisement_SubCategory,
            categoryID: e.target.value,
            selectedValue: text
        })
    }

    showToast = (event, value, type) => {
        if (type == "false") {
            toast.error(value, {
                position: "bottom-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        } else {
            toast.success(value, {
                position: "bottom-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            const { params, navigate } = this.props;
            navigate('/ilan/')
        }
    }
    render() {


        return (
            <>
                <Header1 />

                {/* <!-- Content --> */}
                <div className="page-content bg-white">


                    <div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + Banner4 + ")" }}>
                        <div className="container">
                            <div className="page-banner-entry">
                                <h1 className="text-white">İlan Aç</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
                                        <li>İlan Aç</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block" id="content-area">


                        <div className="section-area section-sp1 bg-gray">
                            <div className="container">
                                <div className="row row-grid checkout-area">
                                    <div className="content-block" id="content-area">

                                        <div className="section-area bg-gray section-sp2">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-7">
                                                        <div className="heading-bx">
                                                            <h6 className="title-ext m-b0">İlan</h6>
                                                            <h3 className="title-head m-b0">İlan Aç</h3>
                                                        </div>
                                                        <div className="row m-b30">
                                                            <div className="col-md-12">
                                                                <form className="ajax-form form-area-box get-in-touch" action="script/contact.php">
                                                                    <div className="ajax-message"></div>
                                                                    <div className="row placeani">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <div className="input-group">
                                                                                    {this.state.ilanKategoriArray.length > 0 ? <select onChange={(e) => this.handleChangeSelected(e)} name="quote-request-reach">
                                                                                        <option value="">Kategori Seçiniz</option>
                                                                                        {this.state.ilanKategoriArray.map(item => (
                                                                                            <option name="selectedValue" label={item.Job_Advertisement_Category_Title} value={item.ID} >{item.Job_Advertisement_Category_Title}</option>

                                                                                        ))}
                                                                                    </select> : null}

                                                                                    {this.state.ilanSubArray.length > 0 ? <select onChange={(e) => this.handleChangeSelectedSubCategory(e)} name="quote-request-reach">
                                                                                        <option value="">AltKategori Seçiniz</option>
                                                                                        {this.state.ilanSubArray.map(item => (

                                                                                            <option name="selectedValue" label={item.Job_Advertisement_SubCategory_Text} value={item.ID} >{item.Job_Advertisement_SubCategory_Text}</option>

                                                                                        ))}
                                                                                    </select> : null}

                                                                                </div>
                                                                            </div>
                                                                        </div>



                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <div className="input-group">

                                                                                    <HtmlEditor onValueChanged={this.valueChangedTitle} defaultValue={markup} valueType="html">
                                                                                        <Toolbar multiline={true}>
                                                                                            <Item name="undo" />
                                                                                            <Item name="redo" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="size" acceptedValues={sizeValues} />
                                                                                            <Item name="font" acceptedValues={fontValues} />
                                                                                            <Item name="separator" />
                                                                                            <Item name="bold" />
                                                                                            <Item name="italic" />
                                                                                            <Item name="strike" />
                                                                                            <Item name="underline" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="alignLeft" />
                                                                                            <Item name="alignCenter" />
                                                                                            <Item name="alignRight" />
                                                                                            <Item name="alignJustify" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="orderedList" />
                                                                                            <Item name="bulletList" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="header" acceptedValues={headerValues} />
                                                                                            <Item name="separator" />
                                                                                            <Item name="color" />
                                                                                            <Item name="background" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="link" />
                                                                                            <Item name="image" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="clear" />
                                                                                            <Item name="codeBlock" />
                                                                                            <Item name="blockquote" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="insertTable" />
                                                                                            <Item name="deleteTable" />
                                                                                            <Item name="insertRowAbove" />
                                                                                            <Item name="insertRowBelow" />
                                                                                            <Item name="deleteRow" />
                                                                                            <Item name="insertColumnLeft" />
                                                                                            <Item name="insertColumnRight" />
                                                                                            <Item name="deleteColumn" />
                                                                                            <Item name="cellProperties" />
                                                                                            <Item name="tableProperties" />
                                                                                        </Toolbar>
                                                                                        <TableContextMenu enabled={true} />
                                                                                        <MediaResizing enabled={true} />

                                                                                    </HtmlEditor>


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <div className="input-group">

                                                                                    <HtmlEditor onValueChanged={this.valueChangedDesc} defaultValue={markupDesc} valueType="html">
                                                                                        <Toolbar multiline={true}>
                                                                                            <Item name="undo" />
                                                                                            <Item name="redo" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="size" acceptedValues={sizeValues} />
                                                                                            <Item name="font" acceptedValues={fontValues} />
                                                                                            <Item name="separator" />
                                                                                            <Item name="bold" />
                                                                                            <Item name="italic" />
                                                                                            <Item name="strike" />
                                                                                            <Item name="underline" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="alignLeft" />
                                                                                            <Item name="alignCenter" />
                                                                                            <Item name="alignRight" />
                                                                                            <Item name="alignJustify" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="orderedList" />
                                                                                            <Item name="bulletList" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="header" acceptedValues={headerValues} />
                                                                                            <Item name="separator" />
                                                                                            <Item name="color" />
                                                                                            <Item name="background" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="link" />
                                                                                            <Item name="image" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="clear" />
                                                                                            <Item name="codeBlock" />
                                                                                            <Item name="blockquote" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="insertTable" />
                                                                                            <Item name="deleteTable" />
                                                                                            <Item name="insertRowAbove" />
                                                                                            <Item name="insertRowBelow" />
                                                                                            <Item name="deleteRow" />
                                                                                            <Item name="insertColumnLeft" />
                                                                                            <Item name="insertColumnRight" />
                                                                                            <Item name="deleteColumn" />
                                                                                            <Item name="cellProperties" />
                                                                                            <Item name="tableProperties" />
                                                                                        </Toolbar>
                                                                                        <TableContextMenu enabled={true} />
                                                                                        <MediaResizing enabled={true} />

                                                                                    </HtmlEditor>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="col-md-12 col-lg-6">

                                                                            <button name="submit" type="submit" value="Submit" className="btn button-md"><i className="fa fa-location-arrow"></i>  Konum Ekle</button>

                                                                        </div>


                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-5">
                                                        <aside className="sticky-top left-border-1" >
                                                            <div className="widget" >
                                                                <h6 className="widget-title">Önizleme</h6>
                                                                <div className="feature-container feature-bx1"  >

                                                                    <div className="case-study-box">


                                                                        <div className="case-content" >
                                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                <span style={{ backgroundColor: '#FDCC03', color: 'white' }}>{this.state.selectedValue}</span>
                                                                                <span style={{ backgroundColor: '#FDCC03', color: 'white' }}> - {this.state.selectedSubValue}</span>
                                                                                <div style={{ justifyContent: 'end', display: 'flex', width: '100%' }}>

                                                                                    <h7 style={{ marginRight: 5 }}> <i className='fa fa-eye'></i>1</h7></div>
                                                                            </div>
                                                                            <h4 className="title" >
                                                                                <div dangerouslySetInnerHTML={{ __html: this.state.valueContentHtmlTitle }} />

                                                                            </h4>
                                                                            <br />
                                                                            <div dangerouslySetInnerHTML={{ __html: this.state.valueContentHtmlDesc }} />
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12 col-lg-6 m-b30" style={{ backgroundColor: 'green' }}>

                                                            </div>



                                                            <div className="widget">
                                                                <h6 className="widget-title">Ödeme Özeti</h6>


                                                                <div className="cart-box">

                                                                    <div className="product-dec">
                                                                        <h6 className="title" style={{ color: 'red' }}>TOPLAM</h6>

                                                                        <span className="product-price" style={{ color: 'red' }}> €0</span>
                                                                    </div>
                                                                    <input onClick={(e) => this.postIlan(e)} type="submit" defaultValue="Submit Comment" className="submit btn button-md" id="submit" name="submit" />

                                                                </div>
                                                            </div>


                                                        </aside>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>



                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />

                </div>

                <Footer1 />


            </>
        );
    }
}

export default ShopCheckout;
