import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PricingTable extends Component{
	render(){
		return(
			<>
				<div className="pricingtable-row">
					<div className="row" style={{justifyContent:'center'}}>
						<div className="col-sm-12 col-md-6 col-lg-3 m-b40">
							<div className="pricingtable-wrapper">
								<div className="pricingtable-inner">
									<div className="pricingtable-main">
										<div className="pricingtable-title">
											<h2>Ücretsiz Kayıt</h2>
								
										</div>
										
									</div>
									<ul className="pricingtable-features">
										<li><i className="fa fa-check"></i> 1 kere güncelleme hakkı</li>
										<li><i className="fa fa-check"></i> Standart Alan</li>
									
									</ul>
									<div className="pricingtable-footer"> 
										<Link to="/rehber-basvur2free" className="btn">Devam Et</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-12 col-md-6 col-lg-3 m-b40">
							<div className="pricingtable-wrapper">
								<div className="pricingtable-inner pricingtable-highlight">
									<div className="pricingtable-main">
										<div className="pricingtable-title">
											<h2>Ücretli Kayıt</h2>
											<p>Senelik</p>
										
										</div>
										<div className="pricingtable-price"> 
											<span className="priceing-doller">₺</span>
											<span className="pricingtable-bx">58.90</span>
											<br/>
											<span className="pricingtable-type">Mobil Ödeme</span>
										</div>
									</div>
									<ul className="pricingtable-features">
										<li><i className="fa fa-check"></i> Tüm Kullanıcılara Bildirim</li>
										<li><i className="fa fa-check"></i> Renkli Çerçeve</li>
										<li><i className="fa fa-check"></i> Renkli Zemin</li>
										<li><i className="fa fa-check"></i> Logo</li>
										<li><i className="fa fa-check"></i> Daha Büyük Bir Alan</li>
										<li><i className="fa fa-check"></i> Farklı Yazı Fontu</li>
								
									</ul>
									<div className="pricingtable-footer"> 
									<Link to="/rehber-basvur2" className="btn">Devam Et</Link>
									</div>
								</div>
							</div>
						</div>
					
					</div>
				</div>
			</>
		);
	}
}

export default PricingTable;
