import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';

// Elements
import Premium from '../../images/gallery/premium.png';

import Banner1 from '../../images/banner/banner1.jpg';

import ServicesPic1 from '../../images/our-services/pic1.jpg';

import axios from "axios";
import ReactHtml from 'raw-html-react';
import LoadingSpinner from "../../LoadingSpinner";

const ilanUrl = "https://getjob.stechomeyazilim.info:5101/getJobAdvertisement/select/";

const imgUrl = "https://berlinesnafadmin.stechomeyazilim.com/Uploads/";
class CaseStudy extends Component {
	constructor() {
		super()
		this.state = {

			ilanArray: [],
			pageNumber:0,
			isLoading: true
		}
	}


	componentDidMount() {

		this._getProducts();
	}


	_getProducts = async () => {
        try{
    await axios.get(`https://getjob.stechomeyazilim.info:5101/getJobAdvertisementPage/select/${this.state.pageNumber}`).then((res) => {
        this.setState({ilanArray: res.data.value, isLoading:false, countData : res.data["@odata.count"] })   
       })
      
       }
       catch (error) {
         
         console.log("_getProducts" + error)
       }
    
    }

	_loadMore = () => {
        console.log("lsndflk",this.state.countData,this.state.pageNumber)
       if(this.state.countData> this.state.pageNumber) {

        this.setState({pageNumber: parseInt(this.state.pageNumber) + 6 },
        ()=> {
            this._getProductsLoadMore( this.state.pageNumber)
        })
    }
    }

	_getProductsLoadMore = async (pageNumber) => {
        try{
       await axios.get(`https://getjob.stechomeyazilim.info:5101/getJobAdvertisementPage/select/${pageNumber}`).then((res) => {
                
       console.log("pageNumber12312312",pageNumber,res.data.value)
       this.setState(prevState => ({
        ilanArray: [...prevState.ilanArray, ...res.data.value]
      }),()=>{
        console.log("pageNumber12312c312",this.state.productsData)

      })

            })
       }
       catch (error) {
         
         console.log("_getProductsLoadMore" + error)
       }
    
    }

	getIlan = async () => {
		try {
			axios.get(ilanUrl).then((res) => {
				console.log("res123", res.data)
				this.setState(
					{
						ilanArray: res.data,
					});
			});
		} catch (error) {
			console.log("error" + error);
		}
	};
	render() {
		return (
			<>
				<Header1 />

				{/* <!-- Content --> */}
				<div className="page-content bg-white">

					<div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + Banner1 + ")" }}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">İlan</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
										<li>İlan</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className="content-block" id="content-area">

						<div className="section-area bg-gray section-sp2">

					
							<a style={{
								backgroundColor: '#FDCC03', padding: 60,
								bottom: -40, position: 'fixed', zIndex: 9, right: -50, borderTopLeftRadius: 150, border: 'solid', borderColor: 'GRAY', borderWidth: 1, boxShadow: '5px 10px 8px 10px #888888'
							}} href="/ilan-ac">
								<p style={{ color: 'black', fontSize: 20, fontWeight: 'bold' }}>İLAN AÇ <i style={{ color: 'black', fontSize: 20 }} className="fa fa-plus"></i><br />	</p>


							</a>

							<div className="container">


								
								<div className='row'>

									{this.state.ilanArray.map(item => (

										item.Job_Advertisement_Image.length != 0 ?

											<div className="col-md-8 col-lg-4 m-b30"   >

												<div style={{ position: 'absolute', top: -15, right: 0, zIndex: 1 }}>
													<img style={{ width: 60 }} src={Premium} alt="" /></div>

												<div className="case-study-box" style={{ border: 'solid', borderColor: item.Job_BorderColor, borderWidth: 3, }}>

													<div className="case-media">
														{item.Job_Advertisement_Image.slice(0,1).map(item2=>
																				<img src={imgUrl+item2.Job_Advertisement_Image_Text} alt="" />
															)}
									
													</div>
													<div className="case-content" style={{ backgroundColor: 'white', padding: 0, paddingTop: 30, paddingBottom: 30 }}>
														<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
															<span className="categary">{item.Job_Advertisement_SubCategory.Job_Advertisement_SubCategory_Text} - {item.Job_Advertisement_SubCategory.Job_Advertisement_Category.Job_Advertisement_Category_Title}</span>
															<div style={{ justifyContent: 'end', display: 'flex', width: '100%' }}>
														
																<h7 style={{ marginRight: 5 }}> <i className='fa fa-eye'></i>{item.Job_Advertisement_Seen}</h7></div>
														</div>
														<h4 className="title" style={{ backgroundColor: item.Job_BackgroundColor, color: item.Job_TitleColor }}>
															<ReactHtml html={item.Job_Advertisement_Title} />
														</h4>

													</div>

													<Link style={{ justifyContent: 'center', display: 'flex', color: 'black' }}
														to={ "/ilan-detail/" + (item.ID)  } state= {{ _item: item }} className="btn">Detaya Git</Link>

												</div>
											</div>
											:
											<div className="col-md-8 col-lg-4 m-b30"   >


												<div className="case-study-box" >


													<div className="case-content" style={{ backgroundColor: 'white', padding: 0, paddingTop: 30, paddingBottom: 30 }}>
														<div style={{ display: 'flex', flexDirection: 'row' }}>
															<span className="categary">{item.Job_Advertisement_SubCategory.Job_Advertisement_SubCategory_Text} - {item.Job_Advertisement_SubCategory.Job_Advertisement_Category.Job_Advertisement_Category_Title}</span>
															<div style={{ justifyContent: 'end', display: 'flex', width: '100%' }}>
																
									            <h7 style={{ marginRight: 5 }}> <i className='fa fa-eye'></i>{item.Job_Advertisement_Seen}</h7></div>
														</div>
														<h4 className="title" style={{ backgroundColor: item.Job_BackgroundColor, color: item.Job_TitleColor }}>
															<ReactHtml html={item.Job_Advertisement_Title} />
														</h4>
													</div>

													<Link style={{ justifyContent: 'center', display: 'flex', color: 'black' }}
														to={"/ilan-detail/" + (item.ID) } state= {{ _item: item }} className="btn">Detaya Git</Link>

												</div>
											</div>


									))}

								</div>
								<div className="pagination-bx gray clearfix text-center" >
									<ul className="pagination">
										<li className="previous"><button class="btn btn-long d-sm-inline-block" onClick={()=> this._loadMore()}>Daha Fazla Yükle</button></li>
									
									</ul>
								</div>

								
							</div>
						</div>
						<br />




					</div>

				</div>

				<Footer1 />
				<LoadingSpinner show={this.state.isLoading} />

			</>
		);
	}
}

export default CaseStudy;