import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

// Images
import Logo from "../../../images/logo-white.png"
import Logo1 from "../../../images/logo2.png"
import ProductImg1 from "../../../images/product/pic1.jpg"
import ProductImg2 from "../../../images/product/pic2.jpg"
import IconImg1 from "../../../images/icon/contact/icon1.png"
import IconImg2 from "../../../images/icon/contact/icon2.png"
import IconImg3 from "../../../images/icon/contact/icon3.png"
import Autharizatiom from "../../../Autharizatiom"
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import LogIn from "../../../Autharizatiom";
import "./navbar.css";
import { withParamsAndNavigate } from "../../../getParamsAndNavigate.js";

class Header2 extends Component{
	constructor() {
		super()
		this.state = {
		  loginData:null,
		  loginVisible : false,
		  registerData : null,
		  checkDisplayMenu:false
		}
	}

	 showDropdownMenu= (event) => {
		event.preventDefault();
		this.setState({checkDisplayMenu : !this.state.checkDisplayMenu})
		document.querySelector('#dropdown').addEventListener('click', this.hideDropdownMenu);
	  
	  }

	  hideDropdownMenu= () => {
		this.setState({checkDisplayMenu : !this.state.checkDisplayMenu})
		  document.querySelector('#dropdown').removeEventListener('click', this.hideDropdownMenu);
	
	  }

	async componentDidMount() {
		 await this._getList()
		// Cart Dropdown 
		var cartBtn = document.getElementById("cartBtn")
        var cartDropdown = document.querySelector(".cart-dropdown")
		
        cartBtn.addEventListener('click',function(){
            cartDropdown.classList.toggle("show")
		})
		
		// Search Form Popup
		var searchBtn = document.getElementById("quik-search-btn")
        var searchForm = document.querySelector(".nav-search-bar")
        var closeBtn = document.getElementById("search-remove")
		
        searchBtn.addEventListener('click',function(){
            searchForm.classList.add("show")
        })

        closeBtn.addEventListener('click',function(){
            searchForm.classList.remove("show")
        })

        // Mobile Menu sidebar function
        var btn = document.querySelector('.menuicon');
        var nav = document.querySelector('.menu-links');
       
        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);

        // Mobile Submenu open close function
        var navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));
        for (var y = 0; y < navMenu.length; y++) {
            navMenu[y].addEventListener('click', function () { menuClick(this) });
        }

        function menuClick(current) {
            const active = current.classList.contains("open")
            navMenu.forEach(el => el.classList.remove('open'));
            
            if(active){
                current.classList.remove('open') 
                console.log("active")
            } else{
                current.classList.add('open');
                console.log("close")
            }
        }
		
    }
	

	_getList= async() => {

		if(JSON.parse(localStorage.getItem('dataLogin')) != null){
			this.setState({loginData : JSON.parse(localStorage.getItem('dataLogin'))})

			console.log("sldknfkl",JSON.parse(localStorage.getItem('dataLogin')))
			this.setState({loginDataUserName :JSON.parse(localStorage.getItem('dataLogin')).Users_Name_Surname})
		}
      

	}

 handleLogIn= async(mail,password) => {
  try {

	await axios.get(`https://getjob.stechomeyazilim.info:5101/getUserLogin/select/${mail}/${password}`)
	.then((res) => {
	  if(res.data.length == 0) {

		this.setState({ alertMessage: 'Kullanıcı adı veya şifreniz yanlış',alertStatus:true,isLoading:false });
	} else {
	  this.setState({
		userID : JSON.stringify(res.data[0].ID)
	  })

	  localStorage.setItem('dataLogin', JSON.stringify(res.data[0]));
	  this.showToast('GetJob', "Başarıyla Sisteme Giriş Yaptınız!", 'success')
}
	})

		  
	  } catch (err) {
		console.log("erccr123",err)
		this.showToast('GetJob', "Kayıt Olurken Bir Hata Oluştu!", 'false')

			 this.setState({ alertMessage: 'Kullanıcı adı veya şifreniz yanlış',alertStatus:true,isLoading: false });	
	


 }
}
 showToast= (event, value, type) => {
	if(type == "false"){
	  toast.error(value, {
		position: "bottom-center",
		autoClose: 4000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
		});
  
	}else{
	  toast.success(value, {
		position: "bottom-center",
		autoClose: 4000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
		});
  
	}
  
  }

	setLogInModal= async() => {
		this.setState({loginVisible:true})
	}

	_closeAuthModal= async() => {
    this.setState({loginVisible:false})
	}


	
	_handleLogOut = () => {
	 //   setLogOutModal(false);
	 this.setState({loginData :null})
    localStorage.removeItem('dataLogin');
    //window.location.reload(false);
    this.props.navigate("/")
    this._getList()
	  };

	 _passsiveUsers = async() => {
        try{
        let loginData =  JSON.parse(localStorage.getItem('dataLogin'))
    
       let user = {
        Users_Active :false
       }

      } catch (error) {
        console.log("_getList" + error)
      }
    
      }

	render(){
		return(
			<>
				{/* <!-- Header Top ==== --> */}
				<header className="header rs-nav header-transparent">
			
					<div className="top-bar">
						<div className="container">
							<div className="row d-flex justify-content-between">
								<div className="topbar-left">

							<div className='flex'>
								<ul>
						

										<li><Link to="#">Berlin Esnaf Sitesi</Link></li>
									</ul>
									
									<ul>
										<li><Link to="#"><i className="la la-phone"></i> (+00) 286 8591</Link></li>
										<li><Link to="#"><i className="las la-envelope-open"></i>berlinesnaf@website.com</Link></li>
									</ul>
									</div>
								</div>
								<div className="topbar-right">
									<ul>
										<li><Link to="/about-1">Hakkımızda</Link></li>
										<li><Link to="/birbilen">Bir Bilen</Link></li>
										<li><Link to="/contact-1">İletişim</Link></li>
										<li><Link to="/signup">Kaydol</Link></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<Sticky enabled={true} className="sticky-header navbar-expand-lg">
						<div className="menu-bar clearfix">
					
						<div className="container clearfix">

		
								{/* <!-- Header Logo ==== --> */}
								<div className="menu-logo " >
								<div className='bg-[white] rounded-bl-md rounded-br-md'><img  className="w-full p-2" src={Logo1}/></div>
								</div>
								{/* <!-- Mobile Nav Button ==== --> */}
								<button className="navbar-toggler collapsed menuicon justify-content-end" type="button" data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
									<span></span>
									<span></span>
									<span></span>
								</button>
								{/* <!-- Author Nav ==== --> */}
								<div className="secondary-menu">
									<div className="secondary-inner">
										<ul>
											<li style={{display:'none'}} className="search-btn">
												<button id="quik-search-btn" type="button" className="btn-link"><i className="ti-search"></i></button>
											</li>
										
										
										
										{/**/}
										
											<li style={{display:'none'}}>
												<Link to="#" className="cart-btn" id="cartBtn">
													<span>0</span>
												</Link>
											
											</li>
											<li className="d-none d-sm-block">
												<Link to="/contact-1" className="btn">İletişim</Link>
											</li>
											<li className="d-none d-sm-block">
												<Link to="/anket" className="btn">ANKET</Link>
											</li>


										

										

										</ul>

										{this.state.loginData != null ?
        <div  className="dropdown" >
         <div className="button cursor-pointer" onClick={this.showDropdownMenu}>{this.state.loginDataUserName}</div>

          { this.state.checkDisplayMenu ? (
          <ul className="cursor-pointer">
       <Link  to={"/employeeınfo/" + (this.state.loginData.ID)} state = {{ _item : this.state.loginData }}  className="flex  hover:bg-blue-900 hover:text-[white]  p-2">               
        <p className="font-[Gotham-Medium] text-right">Profile Git</p>
                
              </Link>
       
              <Link  to={"/sss/"}  className="flex  hover:bg-blue-900 hover:text-[white]  p-2">               
                <p className="font-[Gotham-Medium] text-right">SSS</p>
              </Link>


              <Link to={"/settings/" + (this.state.loginData.ID)} state= {{ _item : this.state.loginData }} className="p-2 hover:bg-blue-900 hover:text-[white] flex " >
            <a className="font-[Gotham-Medium] text-right">Hesabımı Düzenle</a>
          </Link>

          <p  className="p-2 hover:bg-blue-900 hover:text-[white] flex " >
            <a className="font-[Gotham-Medium] text-right">Bildirim</a>
          </p>

          <button onClick={()=> this._passsiveUsers()} className=" w-full flex p-2 hover:bg-blue-900 hover:text-[white]" >
            <a className="font-[Gotham-Medium] text-right">Hesabımı Dondur</a>
          </button>

              <button className="w-full px-8 py-1 mt-1  bg-[#f8d581] hover:text-[white] hover:bg-blue-900 text-[#081F43]"  onClick={() => this._handleLogOut(false)}>
                  <p className="font-[Gotham-Medium]">Log Out</p>
                
              </button>
          </ul>
        ):
        (
          null
        )
        }
       </div> : 	<li className="d-none d-sm-block">
												<button onClick={() => this.setLogInModal()} to="/anket" className="btn">Giriş Yap</button>
											</li>
	}

									</div>

								
								</div>
								{/* <!-- Navigation Menu ==== --> */}
								<div className="menu-links navbar-collapse collapse justify-content-end" id="menuDropdown">
	
									<div className="menu-logo bg-black">
										<Link to="/"><img src={Logo1} alt=""/></Link>
									</div>
									<ul className="nav navbar-nav">	
										<li className="active"><Link to="/">Anasayfa</Link>
										
										</li>

										<li><Link  to="/about-1">Hakkımızda</Link>
										
										</li>

										<li><Link to="/rehber">Rehber</Link>
										
										</li>

										<li><Link to="/aktuel">Aktüel</Link>
										
										</li>

										<li><Link to="/ilan">İlan</Link>
										
										</li>
											
										<li><Link to="/birbilen">Bir Bilen</Link>
										
										</li>

							           <li className="has-mega-menu "><Link to="#">Projects <i className="fa fa-chevron-down"></i></Link>
                                            <ul className="mega-menu">
                                                <li><Link to="#">Projects Grid</Link>
                                                    <ul>
                                                        <li><Link to="projects-grid-2"><span>Projects Grid 2</span></Link></li>
                                                        <li><Link to="projects-grid-3"><span>Projects Grid 3</span></Link></li>
                                                        <li><Link to="projects-grid-4"><span>Projects Grid 4</span></Link></li>
                                                        <li><Link to="projects-grid-wide"><span>Projects Grid Wide</span></Link></li>
														<li><Link to="pricing"><span>Pricing</span></Link></li>
                                                        <li><Link to="appointment"><span>Appointment</span></Link></li>
                                                        <li><Link to="services-1"><span>Services Simple</span></Link></li>
                                                        <li><Link to="services-2"><span>Services Classic</span></Link></li>
                                                        <li><Link to="services-3"><span>Services Modern</span></Link></li>
                                                        <li><Link to="services-details"><span>Services Details</span></Link></li>
														<li><Link to="case-study"><span>Case study</span></Link></li>
                                                        <li><Link to="case-details"><span>Case Details</span></Link></li>
                                                        <li><Link to="company-history"><span>Company History</span></Link></li>
                                                        <li><Link to="team"><span>Team</span></Link></li>
                                                        <li><Link to="error-404"><span>404 Page</span></Link></li>
                                                        <li><Link to="client"><span>Clients</span></Link></li>
														<li><Link to="coming-soon"><span>Coming Soon</span></Link></li> 
                                                        <li><Link to="faq-1"><span>FAQ's 1</span></Link></li>
                                                        <li><Link to="faq-2"><span>FAQ's 2</span></Link></li>
                                                        <li><Link to="contact-1"><span>Contact Us 1</span></Link></li>
                                                
                                                    </ul>
                                                </li>
                                                <li><Link to="#">Projects Masonry</Link>
                                                    <ul>        <li><Link to="contact-2"><span>Contact Us 2</span></Link></li>
                                                        <li><Link to="get-in-touch"><span>Get In Touch</span></Link></li>
														<li><Link to="blog-standard"><span>Blog Standard</span></Link></li>
                                                <li><Link to="blog-classic"><span>Blog Classic</span></Link></li>
                                                <li><Link to="blog-classic-sidebar"><span>Blog Classic Sidebar</span></Link></li>
                                                <li><Link to="blog-list"><span>Blog List Sidebar</span></Link></li>
                                                <li><Link to="blog-masonry"><span>Masonry</span></Link></li>
                                                <li><Link to="blog-details-sidebar"><span>Blog Details</span></Link></li>
                                                        <li><Link to="projects-masonry-grid-2"><span>Masonry Grid 2</span></Link></li>
                                                        <li><Link to="projects-masonry-grid-3"><span>Masonry Grid 3</span></Link></li>
                                                        <li><Link to="projects-masonry-grid-4"><span>Masonry Grid 4</span></Link></li>
                                                        <li><Link to="projects-masonry-grid-wide"><span>Masonry Grid Wide</span></Link></li>
                                                    </ul>
                                                </li>
                                                <li><Link to="#">Projects Style</Link>
                                                    <ul>
                                                        <li><Link to="projects-carousel-1"><span>Projects Carousel 1</span></Link></li>
                                                        <li><Link to="projects-carousel-2"><span>Projects Carousel 2</span></Link></li>
                                                        <li><Link to="projects-carousel-3"><span>Projects Carousel 3</span></Link></li>
                                                        <li><Link to="projects-carousel-4"><span>Projects Carousel 4</span></Link></li>
                                                    </ul>
                                                </li>
                                                <li><Link to="#">Projects Single</Link>
                                                    <ul>
                                                        <li><Link to="projects-single-1"><span>Projects Single 1</span></Link></li>
                                                        <li><Link to="projects-single-2"><span>Projects Single 2</span></Link></li>
                                                        <li><Link to="projects-single-3"><span>Projects Single 3</span></Link></li>
                                                        <li><Link to="projects-single-4"><span>Projects Single 4</span></Link></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
								
									</ul>
									<div className="nav-social-link">
										<Link to="#"><i className="fa fa-facebook"></i></Link>
										<Link to="#"><i className="fa fa-google-plus"></i></Link>
										<Link to="#"><i className="fa fa-linkedin"></i></Link>
									</div>
								</div>
								{/* <!-- Navigation Menu END ==== --> */}
							</div>
						</div>
					</Sticky>
					{/* <!-- Search Box ==== --> */}
					<div className="nav-search-bar">
						<form action="#">
							<input name="search" type="text" className="form-control" placeholder="Type to search"/>
							<span><i className="ti-search"></i></span>
						</form>
						<span id="search-remove"><i className="ti-close"></i></span>
					</div>
				</header>
				{/* <!-- Header Top END ==== --> */}
				<div className="contact-sidebar content-scroll">
					<div className="inner">
						<div className="logo">
							<img src={Logo} alt=""/>
						</div>
						<h4 className="title">Contact Us For Any Informations</h4>
						<ul className="contact-infolist">
							<li>
								<img src={IconImg1} alt=""/>
								<h6 className="contact-title">Contact Number</h6>
								<p>+001 123 456 790 <br/>(02)  3424 44 00</p>
							</li>
							<li>
								<img src={IconImg2} alt=""/>
								<h6 className="contact-title">Email Address</h6>
								<Link to="#">info@yourdomain.com</Link>
								<Link to="#">example@support.com</Link>
							</li>
							<li>
								<img src={IconImg3} alt=""/>
								<h6 className="contact-title">Address</h6>
								<p>2005 Stokes Isle Apt. 896, Venaville 10010, USA</p>
							</li>
						</ul>
						<h4 className="title">Get In Touch</h4>
						<form className="contact-bx ajax-form" action="script/contact.php">
							<div className="ajax-message"></div>
							<div className="row placeani">
								<div className="col-lg-12">
									<div className="form-group">
										<div className="input-group">
											<label>Your Name</label>
											<input name="name" type="text" required className="form-control"/>
										</div>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="form-group">
										<div className="input-group"> 
											<label>Your Email Address</label>
											<input name="email" type="email" className="form-control" required />
										</div>
									</div>
								</div>
								<div className="col-lg-12">
									<div className="form-group">
										<div className="input-group">
											<label>Type Message</label>
											<textarea name="message" rows="4" className="form-control" required ></textarea>
										</div>
									</div>
								</div>
								<div className="col-lg-12">
									<button name="submit" type="submit" value="Submit" className="btn button-md"> Send Message</button>
								</div>
							</div>
						</form>
					</div>

				</div>

				{/*<LogIn _setOtpModal={this._setOtpModal} openLoginModalVisible={this.state.loginVisible}  _closeLoginModal={this._closeLoginModal} handleSignUp={this.handleSignUp} />*/}

				<Autharizatiom _closeLoginOpenSingUpModal={this._closeLoginOpenSingUpModal} openAuthModalVisible= {this.state.loginVisible} _closeAuthModal={this._closeAuthModal} handleLogIn={this.handleLogIn} />
				<div className="contact-sidebtn close">
					<i className="ti-close"></i>
				</div>

			</>
		);
	}
}

export default withParamsAndNavigate(Header2);