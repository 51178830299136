import React, { Component } from 'react';
import { Link } from 'react-router-dom';


// Layout
import Header2 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';
import ReactHtml from 'raw-html-react';
import base64 from 'react-native-base64';
// Elements
import Moment from 'moment'
import axios from "axios";
// Images

import { withParamsAndNavigate } from "../../getParamsAndNavigate.js";

const imgUrl = "https://berlinesnafadmin.stechomeyazilim.com";
const aktuelUrlpost = "https://getjob.stechomeyazilim.info:5101/postNewsComment/send";
const aktuellikeUrlPost = "https://getjob.stechomeyazilim.info:5101/postNewsLiked/send";
const aktuelCommentlikeUrlPost = "https://getjob.stechomeyazilim.info:5101/postNewsCommentLike/send";


class ServicesDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: [],

		};
	}

	async componentDidMount() {
		await this._getList()
	}

	_getList = async () => {

		console.log("lnsdlfk", this.props.location.state)
		try {
			await axios.get(`https://getjob.stechomeyazilim.info:5101/getNewsDetail/select/${this.props.location.state._item.ID}`)
				.then((res) => {
					console.log("lnsd1lfk", res.data)

					this.setState({ list: res.data })
				})
		}
		catch (error) {
			console.log("errorccAS" + error)
		}
	}

	postAktuel = async (e) => {
		e.preventDefault()
		try {

			var testDateUtc = Moment.utc(new Date());
			var localDate = Moment(testDateUtc).local();
			let data = {
				News_Comment_Text: this.state.commentText,
				Users_ID: 20,
				News_ID: this.props.location.state._item.ID,
				News_Comment_Created_DateTime: localDate,
				News_Comment_NickName: this.state.nameText
			}
			axios.post(aktuelUrlpost, data).then(async (res) => {
				await this._getList()
			})
		}

		catch (error) {
			console.log("errorccAS" + error)
		}
	}

	_changeText = (e, type) => {
		if (type == "comment") {
			this.setState({ commentText: e.target.value });
		}
		if (type == "name") {
			this.setState({ nameText: e.target.value });
		}
	}

	postAktuelLike = async (e) => {
		e.preventDefault()
		try {

			let data = {

				News_ID: this.props.location.state._item.ID,
				Users_ID: 93,

			}

			console.log("res123data", data)

			axios.post(aktuellikeUrlPost, data).then(async (res) => {
				console.log("res123", res)
				await this._getList()
			})
		}

		catch (error) {
			console.log("errorccAS" + error)
		}
	}


	postAktuelCommentLike = async (e, ID) => {
		e.preventDefault()
		try {

			let data = {

				Users_ID: 20,
				News_Comment_ID: ID

			}

			console.log("res123data", data)

			axios.post(aktuelCommentlikeUrlPost, data).then(async (res) => {
				console.log("res123", res)
				await this._getList()
			})
		}

		catch (error) {
			console.log("errorccAS" + error)
		}
	}


	render() {
		return (
			<>
				<Header2 />

				{/* <!-- Content --> */}
				{this.state.list.length > 0 ? <div className="page-content bg-white">

					<div className="page-banner ovbl-dark parallax bg-black" >
						<div className="container">
							<div className="page-banner-entry">
						
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
						
										<li>Aktüel</li>
									</ul>
								</div>
								<h1 style={{top:50}} className="text-white">{this.state.list[0].News_Title}</h1>
							</div>
						</div>
					</div>

			
					<div className="content-block" id="content-area">

						<div className="section-area section-sp2 bg-fix ">
							<div className="container about-video">
								<div className="row" >

									<div className="col-lg-12 col-md-7 service-textarea portfolio-content ">
					
										<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
											<div className="col-md-6 m-b15" >
												<img src={imgUrl + this.state.list[0].News_Image} className="radius-sm imghight" alt="" />
											</div>
											<ReactHtml html={base64.decode(base64.decode(this.state.list[0].News_Desc))} />

										</div>
										<button onClick={(e) => this.postAktuelLike(e)} style={{ padding: 5, width: 40, height: 40, justifyContent: 'center', alignItems: 'right', display: 'flex', backgroundColor: 'red', boxShadow: '2px 1px 5px gray', borderColor: 'gray', borderWidth: 0.3, color: 'white' }} to="#" className="comment-reply-link">
											<i style={{ color: 'white', fontSize: 20 }} className='fa fa-thumbs-up'></i>{this.state.list[0].News_Liked.length}</button>

										<hr style={{
												color: 'red',
												backgroundColor: '#FDCC03',
												height: 0.5}}
										/>

									</div>
								</div>
							</div>


							<div className="section-area section-sp2 bg-fix bg-gray">
								<div className="container about-video">
									<div className="row">
										<div className="col-lg-12 col-md-7 service-textarea portfolio-content">


											{this.state.list[0].News_Comment.length != 0 ?
												<div className="clear" id="comment-list">
													<div className="comments-area" id="comments">
														<h2 className="comments-title">{this.state.list[0].News_Comment.length} Yorum</h2>
														<div className="clearfix">
															{/* <!-- comment list END --> */}
															<ol className="comment-list">

																{this.state.list[0].News_Comment.map(item2 => (
																	<li className="comment"><div className="comment-body">
																		<div className="comment-author vcard">
																			<cite className="fn">{item2.News_Comment_NickName}</cite> <span className="says">says:</span> </div>
																		<div className="comment-meta"> 	{Moment(item2.News_Comment_Created_DateTime).format('lll')}</div>
																		<p> {item2.News_Comment_Text}</p>

																		<div style={{ position: 'absolute', pointerEvents: 'initial', right: 3, bottom: 10, flexDirection: 'column', display: 'flex', alignItems: 'center' }}>

																			<button onClick={(e) => this.postAktuelCommentLike(e, item2.ID)} style={{ padding: 5, width: 40, height: 40, borderRadius: 20, justifyContent: 'center', alignContent: 'center', display: 'flex', backgroundColor: 'red', borderColor: 'lightgray', boxShadow: '2px 1px 5px white' }} to="#" className="comment-reply-link">
																				<i style={{ color: 'white', fontSize: 20 }} className='fa fa-thumbs-up'></i></button>
																			<p style={{ color: 'white' }}>{item2.News_Comment_Liked.length} beğeni</p>
																		</div>
																		<div className="comment-meta"> <Link to="#">{item2.News_Comment_NickName}</Link> </div>

																	</div>
																	</li>

																))}

																{/* <!-- list END --> */}



															</ol>
															<div className="comment-respond " style={{ backgroundColor: 'black' }} id="respond">
																<h4 className="comment-reply-title" style={{ color: '#FDCC03' }} id="reply-title">Yorum Yap <small> <Link style={{ display: "none" }} to="#" id="cancel-comment-reply-link" rel="nofollow">Cancel reply</Link> </small> </h4>
																<div className="comment-form" id="commentform" >
																	<p className="comment-form-comment">

																		<input onChange={(e) => this._changeText(e, 'name')} type="text" defaultValue="" name="İsminiz" placeholder="İsminiz" id="author" />
																	</p>

																	<p className="comment-form-comment">

																		<textarea onChange={(e) => this._changeText(e, 'comment')} rows="8" name="comment" placeholder="Yorum" id="comment"></textarea>
																	</p>
																	<p className="form-submit" style={{ justifyContent: 'center', display: 'flex' }}>
																		<input onClick={(e) => this.postAktuel(e)} type="submit" defaultValue="Submit Comment" className="submit" id="submit" name="submit" />
																	</p>
																</div>
															</div>
															{/* <!-- comment list END --> */}
															{/* <!-- Form --> */}

															{/* <!-- Form --> */}
														</div>


													</div>



												</div>
												:

												<div className="clear" id="comment-list">
													<div className="comments-area" id="comments">

														<div className="comment-respond " style={{ backgroundColor: 'black' }} id="respond">
															<h4 className="comment-reply-title" style={{ color: '#FDCC03' }} id="reply-title">Yorum Yap <small> <Link style={{ display: "none" }} to="#" id="cancel-comment-reply-link" rel="nofollow">Cancel reply</Link> </small> </h4>
															<form className="comment-form" id="commentform" method="post">
																<p className="comment-form-comment">
																	<label htmlFor="author">İsminiz <span className="required">*</span></label>
																	<input onChange={(e) => this._changeText(e, 'name')} type="text" defaultValue="" name="İsminiz" placeholder="İsminiz" id="author" />
																</p>

																<p className="comment-form-comment">
																	<label htmlFor="comment">Yorumunuz</label>
																	<textarea onChange={(e) => this._changeText(e, 'comment')} rows="8" name="comment" placeholder="Yorum" id="comment"></textarea>
																</p>
																<p className="form-submit" style={{ justifyContent: 'center', display: 'flex' }}>
																	<input onClick={(e) => this.postAktuel(e)} type="submit" defaultValue="Submit Comment" className="submit" id="submit" name="submit" />
																</p>
															</form>
														</div>
													</div>


												</div>}


										</div>
									</div>
								</div>
							</div>

						</div>


					</div>

				</div> : null}

				<Footer1 />

			</>
		);
	}
}

export default withParamsAndNavigate(ServicesDetails);
