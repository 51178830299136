import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';

// Images
import Banner1 from '../../images/banner/banner1.jpg';


class GetInTouch extends Component {

    render() {
        return (
            <>
                <Header1 />

                {/* <!-- Content --> */}
                <div className="page-content bg-white">

                    <div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + Banner1 + ")" }}>
                        <div className="container">
                            <div className="page-banner-entry">
                                <h1 className="text-white">Kaydol</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
                                        <li>Kaydol</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block" id="content-area">

                        <div className="section-area bg-gray section-sp2 ">
                            <div className="container ">
                                <div className="row " style={{ justifyContent: 'center',display:'flex' }}>
                                    <div className="col-lg-6 col-md-7" style={{padding:10,borderRadius:20, border: '1px solid #E5E4E2',   boxShadow: '1px 2px 9px gray',margin: '4em',padding: '1em'}}>
                                        <div className="heading-bx">
                                            <h6 className="title-ext m-b0">Berlin Esnaf</h6>
                                            <h3 className="title-head m-b0">Berlin Esnaf</h3>
                                            <div className="ttr-separator sepimg"></div>
                                            <p className="head-px2">Formu doldurup Berlin Esnaf'a üye olabilirsiniz.</p>
                                        </div>
                                        <div className="row m-b30" >
                                            <div className="col-md-12"  >
                                                <form className="ajax-form form-area-box get-in-touch"  action="#">
                                                    <div className="ajax-message"></div>
                                                    <div className="row placeani" >
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="input-group">

                                                                    <input placeholder='İsim Soyisim' name="name" type="text" className="form-control" required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="input-group">

                                                                    <input placeholder='Email Adresiniz*' name="email" type="email" className="form-control" required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="input-group">

                                                                    <input placeholder='Şifreniz*' name="sifre" type="email" className="form-control" required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="input-group">

                                                                    <input placeholder='Şifrenizi Yeniden Giriniz*' name="sifre" type="email" className="form-control" required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="input-group">

                                                                    <input placeholder='Telefon numaranız' name="telefon" type="text" className="form-control" required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="input-group">

                                                                    <input placeholder='Nickname' name="nickname" type="text" className="form-control" required />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="input-group">

                                                                    <input placeholder='Facebook Adresiniz' name="facebook" type="text" className="form-control" required />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <div className="input-group">

                                                                    <input placeholder='Twitter Adresiniz' name="twitter" type="text" className="form-control" required />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div style={{justifyContent:'center',display:'flex'}} className="col-lg-12">
                                                            <button  name="submit" type="submit" value="Submit" className="btn button-md">KAYDOL</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>



                    </div>

                </div>

                <Footer1 />

            </>
        );
    }
}

export default GetInTouch;