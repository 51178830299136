import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// Layout
import Header1 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';
// Images
import Banner4 from "../../images/banner/banner4.jpg"
import ServicesPic1 from '../../images/our-services/pic1.jpg';
import axios from "axios";
import Moment from 'moment';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'devextreme/dist/css/dx.light.css';
import { HtmlEditor } from 'devextreme-react/html-editor';
import {
    Toolbar,
    Item,
    TableContextMenu,
    MediaResizing
} from "devextreme-react/html-editor";


import { tabs } from './data.js';
const sizeValues = ["8pt", "10pt", "12pt", "14pt", "18pt", "24pt", "36pt"];
const fontValues = ["Arial", "Georgia", "Tahoma", "Times New Roman", "Verdana"];
const headerValues = [false, 1, 2, 3, 4, 5];

const ilanUrl = "https://getjob.stechomeyazilim.info:5101/getJobAdvertisementCategory/select/";
const ilanUrlPost = "https://getjob.stechomeyazilim.info:5101/postJobAdvert/send";
const ilanUrlPostImage = "https://getjob.stechomeyazilim.info:5101/postJobAdvImage/send";

const markup = `
    <div>
        <h2>
            Berlin Esnaf İlan Başlığını giriniz ..
        </h2> </div>
`;
const markupDesc = `
<div>
    <h2>
        Berlin Esnaf İlan Detaylarını giriniz ..
    </h2> </div>
`;


const imageUrlPost = "       ";
class ShopCheckout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            name: "",
            detail: "",
            selectedValue: "",
            baslikbtnname: "",
            baslikbtnprice: 0,
            borderbtnname: "",
            borderbtnprice: 0,
            backgrndbtnname: "",
            backgrndbtnprice: 0,
            ilanKategoriArray: [],
            latitude: null,
            longitude: null,
            file: null,
            imageArray: [],
            imageArrayData: [],
            ilanKategoriArray: [],
            ilanSubArray:[],
            valueContentHtmlTitle: '',
            valueContentHtmlDesc: ''

        };

        this.tabs = tabs;
        this.multilineChanged = this.multilineChanged.bind(this);
        this.currentTabChanged = this.currentTabChanged.bind(this);
        this.changeTitleColor = this.changeTitleColor.bind(this);
        this.changeBackgroundColor = this.changeBackgroundColor.bind(this);
        this.changeBorderColor = this.changeBorderColor.bind(this);
        this.uploadSingleFile = this.uploadSingleFile.bind(this);
        this.valueChangedTitle = this.valueChangedTitle.bind(this);
        this.valueChangedDesc = this.valueChangedDesc.bind(this);

    }



    valueChangedDesc(e) {
        this.setState({
            valueContentHtmlDesc: e.value,
        
        });
        console.log('olmazzz',e.value);
    }

    valueChangedTitle(e) {
        this.setState({
            valueContentHtmlTitle: e.value,
        });
    }

    multilineChanged(e) {
        this.setState({
            isMultiline: e.value,
        });
    }

    currentTabChanged(e) {
        this.setState({
            currentTab: e.value,
        });
    }

    uploadSingleFile(e) {
        let files = e.target.files;
        let fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);

        fileReader.onload = (event) => {


            this.setState(prevState => ({
                imageArray: [...prevState.imageArray, { imageData: event.target.result }]
            }))

            console.log("hvhjvj12", event)
            this.setState({ filePreview: event.target.result })
        }
    }

    upload = async () => {
        try {

            this.state.imageArray.map((item, index) => {
                const formData = { image: item.imageData }

                let endpoint = "https://getjob.stechomeyazilim.info:5101/fileUploadBerlin/insert/";
                axios.post(endpoint, formData, {
                }).then((res) => {

                    this.setState(prevState => ({
                        imageArrayData: [...prevState.imageArrayData, { data: res.data }]
                    }))
                })
            })

        }

        catch (error) {
            console.log("upload" + error)
        }
    }

    position = async () => {
        await navigator.geolocation.getCurrentPosition(
            position => this.setState({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            }),
            err => console.log(err)
        );
        console.log(this.state.latitude)
    }
    postIlan = async (e) => {
        e.preventDefault()
        try {

            console.log("lsdnf", this.state.selectedValue)
            var testDateUtc = Moment.utc(new Date());
            var localDate = Moment(testDateUtc).local();
            let data = {
              
                Job_Advertisement_Desc: this.state.valueContentHtmlDesc,
                Job_Advertisement_DateTime: localDate,
                Users_ID: 20,
                Job_Advertisement_Type_ID: 2,
                Job_Advertisement_Liked: null,
                Job_Advertisement_Seen: null,
                Job_Advertisement_Active: null,
                Job_Advertisement_Users_Complated_ID: null,
                Job_BackgroundColor: this.state.backgrndbtnname,
                Job_BorderColor: this.state.borderbtnname,
                Job_TitleColor: this.state.baslikbtnname,
                Job_Premium_Font: this.state.fontText,
                Job_BorderWidth: null,
                Job_Advertisement_Address: null,
                Job_Advertisement_Lat: null,
                Job_Advertisement_Lng: null,
                Job_Advertisement_SubCategory_ID: this.state.categorySubID,
                Job_Advertisement_Title: this.state.valueContentHtmlTitle,
                Job_Advertisement_Video: null
            }
            axios.post(ilanUrlPost, data).then((res) => {

                this.state.imageArrayData.map((item, index) => {
                    let data = {
                        Job_Advertisement_Image_Text: item.data,
                        Job_Advertisement_ID: res.data.value[0].ID
                    }


                    axios.post(ilanUrlPostImage, data).then(async (res) => {
                        console.log('dsd')
                    })
                })
                this.showToast('BerlinEsnaf', "İlanınız başarıyla sisteme yüklenmiştir!", 'success')


            })
        }

        catch (error) {
            console.log("errorccAS" + error)
        }
    }





    postImage = async (e) => {
        e.preventDefault()
        try {

            let data = {
                ID: 169,
                Job_Advertisement_Image_Text: "",
                Job_Advertisement_ID: 1

            }
            axios.post(imageUrlPost, data).then(async (res) => {
                console.log('dsd')
            })
        }

        catch (error) {
            console.log("errorccAS" + error)
        }
    }
    componentDidMount() {

        this.getIlanKategori();

    }
    getIlanKategori = async () => {
        try {
            axios.get(ilanUrl).then((res) => {
                console.log("res123", res.data)
                this.setState(
                    {
                        ilanKategoriArray: res.data,

                    });
            });
        } catch (error) {
            console.log("error" + error);
        }
    };
    changeTitleColor(e) {

        this.setState({ baslikbtnname: e.target.name, baslikbtnprice: 72 });
    }
    changeBorderColor(e) {
        const id = e.target.name;
        console.log(id);

        this.setState({ borderbtnname: e.target.name, borderbtnprice: 72 });
    }
    changeBackgroundColor(e) {

        this.setState({ backgrndbtnname: e.target.name, backgrndbtnprice: 24 });
    }

    changeName = (e) => {
        this.setState({ name: e.target.value });
    }


    changeDetail = (e) => {
        this.setState({ detail: e.target.value });
    }




    handleChange = (selectedOptions) => {
        this.setState({ selectedOptions });
    }

    handleChangeSelectedSubCategory= (e) => {
        console.log("lnkfsdklf", e.target)

        var index = e.nativeEvent.target.selectedIndex;
        var text = e.nativeEvent.target[index].text;

        this.setState({
            categorySubID: e.target.value,
            selectedSubValue: text
        })
    }

    handleChangeSelected = (e) => {
        var index = e.nativeEvent.target.selectedIndex;
        var text = e.nativeEvent.target[index].text;

       let subCategory = this.state.ilanKategoriArray.filter((x)=> x.Job_Advertisement_Category_Title == e.nativeEvent.target[index].text)
     
        this.setState({
            ilanSubArray : subCategory[0].Job_Advertisement_SubCategory,
            categoryID: e.target.value,
            selectedValue: text
        })
    }
    FontChange2 = (e) => {
        this.setState({ fontText: e.target.value })
    }


    showToast = (event, value, type) => {
        if (type == "false") {
            toast.error(value, {
                position: "bottom-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

        } else {
            toast.success(value, {
                position: "bottom-center",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });

            const { params, navigate } = this.props;
            navigate('/ilan/')
        }
    }

    render() {


        const Fonts = [
            {
                id: 1,
                title: 'CerebriSans-Medium',
            },
            {
                id: 2,
                title: 'CerebriSans-MediumItalic',
            },
            {
                id: 2,
                title: 'CerebriSans-LightItalic',
            }
        ]
        const Colors = [
            {
                id: 1,
                title: 'red',
            },
            {
                id: 2,
                title: 'blue',
            },
            {
                id: 3,
                title: 'green',
            },
            {
                id: 4,
                title: 'orange',
            },
            {
                id: 5,
                title: 'pink',
            },
            {
                id: 6,
                title: 'purple',
            },
            {
                id: 7,
                title: 'yellow',
            },
            {
                id: 8,
                title: 'gray',
            },
            {
                id: 9,
                title: 'darkblue',
            },
            {
                id: 10,
                title: 'lightgreen',
            }, ,
            {
                id: 11,
                title: 'lightgray',
            },
            {
                id: 12,
                title: 'cyan',
            },
            {
                id: 13,
                title: 'lightblue',
            },
            {
                id: 14,
                title: 'darkorange',
            },
            {
                id: 15,
                title: 'white',
            },
            {
                id: 16,
                title: 'black',
            },
            {
                id: 17,
                title: 'brown',
            },
            {
                id: 18,
                title: 'chocolate',
            }
        ]
        return (
            <>
                <Header1 />

                {/* <!-- Content --> */}
                <div className="page-content bg-white">

                    <div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + Banner4 + ")" }}>
                        <div className="container">
                            <div className="page-banner-entry">
                                <h1 className="text-white">İlan Aç</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
                                        <li>İlan Aç</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content-block" id="content-area">

                        <div className="section-area section-sp1 bg-gray">
                            <div className="container">
                                <div className="row row-grid checkout-area">
                                    <div className="content-block" id="content-area">

                                        <div className="section-area bg-gray section-sp2">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-7">
                                                        <div className="heading-bx">
                                                            <h6 className="title-ext m-b0">İlan</h6>
                                                            <h3 className="title-head m-b0">İlan Aç</h3>
                                                        </div>
                                                        <div className="row m-b30">
                                                            <div className="col-md-12">
                                                                <form className="ajax-form form-area-box get-in-touch" action="script/contact.php">
                                                                    <div className="ajax-message"></div>
                                                                    <div className="row placeani">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <div className="input-group">
                                                                                    

                                                                                { this.state.ilanKategoriArray.length > 0 ?  <select onChange={(e) => this.handleChangeSelected(e)} name="quote-request-reach">
                                                                                        <option value="">Kategori Seçiniz</option>
                                                                                        {this.state.ilanKategoriArray.map(item => (
                                                                                                <option name="selectedValue" label={item.Job_Advertisement_Category_Title} value={item.ID} >{item.Job_Advertisement_Category_Title}</option>
                                                                                           
                                                                                        ))}
                                                                                    </select> : null}

                                                                                    {this.state.ilanSubArray.length > 0 ? <select onChange={(e) => this.handleChangeSelectedSubCategory(e)} name="quote-request-reach">
                                                                                        <option value="">AltKategori Seçiniz</option>
                                                                                        {this.state.ilanSubArray.map(item => (
                                                                                        
                                                                                                <option name="selectedValue" label={item.Job_Advertisement_SubCategory_Text} value={item.ID} >{item.Job_Advertisement_SubCategory_Text}</option>
                                                                                          
                                                                                        ))}
                                                                                    </select> : null}


                                                                                </div>
                                                                            </div>
                                                                        </div>



                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <div className="input-group">
                                                                                    <HtmlEditor onValueChanged={this.valueChangedTitle} defaultValue={markup} valueType="html">
                                                                                        <Toolbar multiline={true}>
                                                                                            <Item name="undo" />
                                                                                            <Item name="redo" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="size" acceptedValues={sizeValues} />
                                                                                            <Item name="font" acceptedValues={fontValues} />
                                                                                            <Item name="separator" />
                                                                                            <Item name="bold" />
                                                                                            <Item name="italic" />
                                                                                            <Item name="strike" />
                                                                                            <Item name="underline" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="alignLeft" />
                                                                                            <Item name="alignCenter" />
                                                                                            <Item name="alignRight" />
                                                                                            <Item name="alignJustify" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="orderedList" />
                                                                                            <Item name="bulletList" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="header" acceptedValues={headerValues} />
                                                                                            <Item name="separator" />
                                                                                            <Item name="color" />
                                                                                            <Item name="background" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="link" />
                                                                                            <Item name="image" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="clear" />
                                                                                            <Item name="codeBlock" />
                                                                                            <Item name="blockquote" />
                                                                                            <Item name="separator" />
                                                                                            <Item name="insertTable" />
                                                                                            <Item name="deleteTable" />
                                                                                            <Item name="insertRowAbove" />
                                                                                            <Item name="insertRowBelow" />
                                                                                            <Item name="deleteRow" />
                                                                                            <Item name="insertColumnLeft" />
                                                                                            <Item name="insertColumnRight" />
                                                                                            <Item name="deleteColumn" />
                                                                                            <Item name="cellProperties" />
                                                                                            <Item name="tableProperties" />
                                                                                        </Toolbar>
                                                                                        <TableContextMenu enabled={true} />
                                                                                        <MediaResizing enabled={true} />

                                                                                    </HtmlEditor>


                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <div className="input-group">


                                                                                    <div className="input-group">

                                                                                        <HtmlEditor onValueChanged={this.valueChangedDesc} defaultValue={markupDesc} valueType="html">
                                                                                            <Toolbar multiline={true}>
                                                                                                <Item name="undo" />
                                                                                                <Item name="redo" />
                                                                                                <Item name="separator" />
                                                                                                <Item name="size" acceptedValues={sizeValues} />
                                                                                                <Item name="font" acceptedValues={fontValues} />
                                                                                                <Item name="separator" />
                                                                                                <Item name="bold" />
                                                                                                <Item name="italic" />
                                                                                                <Item name="strike" />
                                                                                                <Item name="underline" />
                                                                                                <Item name="separator" />
                                                                                                <Item name="alignLeft" />
                                                                                                <Item name="alignCenter" />
                                                                                                <Item name="alignRight" />
                                                                                                <Item name="alignJustify" />
                                                                                                <Item name="separator" />
                                                                                                <Item name="orderedList" />
                                                                                                <Item name="bulletList" />
                                                                                                <Item name="separator" />
                                                                                                <Item name="header" acceptedValues={headerValues} />
                                                                                                <Item name="separator" />
                                                                                                <Item name="color" />
                                                                                                <Item name="background" />
                                                                                                <Item name="separator" />
                                                                                                <Item name="link" />
                                                                                                <Item name="image" />
                                                                                                <Item name="separator" />
                                                                                                <Item name="clear" />
                                                                                                <Item name="codeBlock" />
                                                                                                <Item name="blockquote" />
                                                                                                <Item name="separator" />
                                                                                                <Item name="insertTable" />
                                                                                                <Item name="deleteTable" />
                                                                                                <Item name="insertRowAbove" />
                                                                                                <Item name="insertRowBelow" />
                                                                                                <Item name="deleteRow" />
                                                                                                <Item name="insertColumnLeft" />
                                                                                                <Item name="insertColumnRight" />
                                                                                                <Item name="deleteColumn" />
                                                                                                <Item name="cellProperties" />
                                                                                                <Item name="tableProperties" />
                                                                                            </Toolbar>
                                                                                            <TableContextMenu enabled={true} />
                                                                                            <MediaResizing enabled={true} />

                                                                                        </HtmlEditor>   </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="col-lg-12">
                                                                            <div className="row m-b20">

                                                                                <div className="col-md-12 col-lg-6" style={{ border: '1px solid #cccccc', borderRadius: 5 }}>

                                                                                    <h5>Başlık Rengi Seç</h5>
                                                                                    <div style={{
                                                                                        color: 'white', backgroundColor: 'black', position: 'absolute', top: -15, right: 0, borderRadius: 10, padding: 5
                                                                                    }}>72Є</div>

                                                                                    <div className=" m-b10" >

                                                                                        {Colors.map(item => (
                                                                                            <input type="button" onClick={this.changeTitleColor} style={{ backgroundColor: item.title, width: 30, height: 30, borderRadius: 15, margin: 5 }} name={item.title} />

                                                                                        ))}

                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-12 col-lg-6" style={{ border: '1px solid #cccccc', borderRadius: 5 }}>
                                                                                    <h5>Çerçeve Rengi Seç</h5>
                                                                                    <div id='border' style={{
                                                                                        color: 'white', backgroundColor: 'black', position: 'absolute', top: -15, right: 0, borderRadius: 10, padding: 5
                                                                                    }}>72Є</div>
                                                                                    <div className=" m-b10">

                                                                                        {Colors.map(item => (
                                                                                            <input type="button" onClick={this.changeBorderColor} style={{ backgroundColor: item.title, width: 30, height: 30, borderRadius: 15, margin: 5 }} name={item.title} />

                                                                                        ))}
                                                                                    </div>
                                                                                </div>





                                                                            </div>
                                                                        </div>


                                                                        <div className="col-md-12 col-lg-6" style={{ border: '1px solid #cccccc', borderRadius: 5 }}>
                                                                            <h5>Arkaplan Rengi Seç</h5>
                                                                            <div style={{
                                                                                color: 'white', backgroundColor: 'black', position: 'absolute', top: -15, right: 0, borderRadius: 10, padding: 5
                                                                            }}>24Є</div>
                                                                            <div className=" m-b10">
                                                                                {Colors.map(item => (
                                                                                    <input type="button" onClick={this.changeBackgroundColor} style={{ backgroundColor: item.title, width: 30, height: 30, borderRadius: 15, margin: 5 }} name={item.title} />

                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-12 col-lg-6">
                                                                            <div className="form-group">
                                                                                <h5>Font Seç</h5>
                                                                                <div className="input-group">
                                                                                    <select onChange={(e) => this.FontChange2(e)} name="quote-request-reach">
                                                                                        <option value="">Font Seçin</option>
                                                                                        {Fonts.map(item => (


                                                                                            <option name="selectedValue2" value={item.title}>{item.title}</option>
                                                                                        ))}
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <input type="file" className="form-control" onChange={this.uploadSingleFile} />

                                                                            <button type="button" className="btn btn-primary btn-block" onClick={this.upload}>Upload</button>
                                                                            <div>

                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-5">
                                                        <aside className="sticky-top left-border-1" >
                                                            <div className="widget" >
                                                                <h6 className="widget-title">Önizleme</h6>
                                                                <div className="feature-container feature-bx1" style={{ border: '3px solid', borderColor: this.state.borderbtnname, backgroundColor: 'white' }} >

                                                                    <div className="case-study-box">
                                                                        {this.state.imageArray.slice(0, 1).map((item, index) => {
                                                                            return (
                                                                                <div>
                                                                                    <img src={item.imageData} alt="" />
                                                                                </div>
                                                                            )
                                                                        })
                                                                        }
                                                                        <div className="case-media">

                                                                        </div>
                                                                        <div className="case-content" >
                                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                            <span style={{backgroundColor:'#FDCC03',color:'white',padding:10}}>{this.state.selectedValue}</span>
                                                                                <span style={{backgroundColor:'#FDCC03',color:'white',padding:10}} > - {this.state.selectedSubValue}</span>

                                                                            </div>
                                                                            <h4 className="title" style={{ color: this.state.baslikbtnname, backgroundColor: this.state.backgrndbtnname }}>
                                                                                <div dangerouslySetInnerHTML={{ __html: this.state.valueContentHtmlTitle }} />

                                                                            </h4>
                                                                            <br />
                                                                            <div dangerouslySetInnerHTML={{ __html: this.state.valueContentHtmlDesc }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="widget">
                                                                <h6 className="widget-title">Ekstra Resimler</h6>

                                                                {this.state.imageArray.slice(1,).map((item, index) => {
                                                                    return (

                                                                        <div className="cart-box">
                                                                            <div className="product-dec">

                                                                                <img style={{ width: 50, margin: 10 }} src={item.imageData} alt="" />
                                                                            </div>
                                                                        </div>
                                                                    )

                                                                })

                                                                }




                                                            </div>

                                                            <div className="widget">
                                                                <h6 className="widget-title">Ödeme Özeti</h6>
                                                                <div className="cart-box">

                                                                    <div className="product-dec">
                                                                        <h6 className="title">Başlık Rengi</h6>

                                                                        <span className="product-price"> {this.state.baslikbtnprice}</span>
                                                                    </div>

                                                                </div>
                                                                <div className="cart-box">

                                                                    <div className="product-dec">
                                                                        <h6 className="title">Arka Plan Rengi</h6>

                                                                        <span className="product-price"> {this.state.backgrndbtnprice}</span>
                                                                    </div>

                                                                </div>
                                                                <div className="cart-box">

                                                                    <div className="product-dec">
                                                                        <h6 className="title">Çerçeve  Rengi</h6>

                                                                        <span className="product-price">{this.state.borderbtnprice}</span>
                                                                    </div>

                                                                </div>
                                                                <div className="cart-box">

                                                                    <div className="product-dec">
                                                                        <h6 className="title" style={{ color: 'red' }}>TOPLAM</h6>

                                                                        <span className="product-price" style={{ color: 'red' }}> {this.state.baslikbtnprice + this.state.borderbtnprice}</span>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className="widget">
                                                                <h6 className="widget-title">Ödeme Yöntemi</h6>
                                                                <ul className="contact-infolist">
                                                                    <li>

                           
                                                                        <Link to={"/odeme2/"} state={{ _totalPrice: this.state.baslikbtnprice + this.state.borderbtnprice }} type="submit" value="Submit" className="btn button-md">KREDİ KARTI ÖDEME</Link>

                                                                     

                                                                    </li>
                                                                    <li>

                                                                    <input onClick={(e) => this.postIlan(e)} type="submit" defaultValue="Submit Comment" className="submit btn button-md" id="submit" name="submit" />


                                                                    </li>



                                                                </ul>
                                                            </div>
                                                        </aside>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>



                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                </div>

                <Footer1 />

            </>
        );
    }
}

export default ShopCheckout;