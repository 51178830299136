import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';

// Elements
import PricingTable from '../elements/pricingtable/pricingtable2';


// Images
import Banner1 from '../../images/banner/banner1.jpg';

class Pricing extends Component {
	
	render(){
		return (
			<>
				<Header1 />
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark parallax" style={{backgroundImage: "url("+Banner1+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">İlan Aç</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
										<li>İlan Aç</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					<div className="content-block" id="content-area">
						
						<div className="section-area bg-gray section-sp1">
							<div className="container">
								<div className="heading-bx text-center">
									<h6 className="title-ext m-b0">İLAN</h6>
									<h2 className="title-head m-b0">Size Uygun Olan Planı Seçiniz</h2>
									<div className="ttr-separator sepimg"></div>
								</div>
								<PricingTable />
							</div>
						</div>
						
				
						
					</div>
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}

export default Pricing;