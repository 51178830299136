import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';
import Game from '../../images/gallery/game.png';
// Images
import Banner1 from "../../images/banner/banner1.jpg";
import Sudoku from "../../images/sudoku.png";
import Kartoyunu from "../../images/kartoyunu.png"

class ProjectGrid2 extends Component {

    render() {
        return (
            <>
                <Header1 />

                {/* <!-- Content --> */}
                <div className="page-content bg-white">

                    <div className="page-banner ovbl-dark parallax" style={{ backgroundImage: "url(" + Banner1 + ")" }}>
                        <div className="container">
                            <div className="page-banner-entry">
                                <h1 className="text-white">Oyunlar</h1>
                                <div className="breadcrumb-row">
                                    <ul className="list-inline">
                                        <li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
                                        <li>Oyunlar</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="content-block" id="content-area">
                        <div className="section-area section-sp1 bg-white">
                            <div className="container" style={{ flexDirection: 'row', display: 'flex' }}>
                                <div className="action-card col-lg-6 col-md-6 col-sm-12" >
                                    <div className="portfolio-box style-1" style={{ boxShadow: '8px 8px 9px gray', margin: '1em', backgroundColor: '#FFFAFA', borderRadius: 100, padding: 30, border: 'solid', borderWidth: 1,borderColor:'gray' }}>
                                        <div className="portfolio-media" >
                                            <img style={{ padding: 30 }} src={Sudoku} alt="" />
                                        </div>
                                        <div className="portfolio-info" style={{ justifyContent: 'center', display: 'flex', backgroundColor: 'transparent' }}>

                                            <span className="exe-title" style={{ fontSize: 50 }}>SUDOKU</span>

                                        </div>

                                        <div className="portfolio-info" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', backgroundColor: 'transparent' }}>

                                            <h4 className="title" style={{ color: 'black' }}>OYNA  <i className="fa fa-gamepad"></i>
                                            </h4>

                                        </div>


                                    </div>
                                </div>


                                <div className="action-card col-lg-6 col-md-6 col-sm-12" >
                                    <Link to='#'>
                                        <div className="portfolio-box style-1" style={{ boxShadow: '8px 8px 9px gray', margin: '1em', backgroundColor: '#FFFAFA', border: 'solid', borderWidth: 1, borderColor:'gray',borderRadius: 100, padding: 30 }}>
                                            <div className="portfolio-media" >
                                                <img style={{ padding: 30 }} src={Kartoyunu} alt="" />
                                            </div>
                                            <div className="portfolio-info" style={{ justifyContent: 'center', display: 'flex', backgroundColor: 'transparent' }}>

                                                <span className="exe-title" style={{ fontSize: 50 }}>KART OYUNU</span>

                                            </div>

                                            <div className="portfolio-info" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', backgroundColor: 'transparent' }}>

                                                <h4 className="title" style={{ color: 'black' }}>OYNA  <i className="fa fa-gamepad"></i>
                                                </h4>

                                            </div>

                                        </div>
                                    </Link>
                                </div>



                            </div>
                        </div>



                    </div>

                </div>

                <Footer1 />

            </>
        );
    }
}

export default ProjectGrid2;