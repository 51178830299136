import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';

// Elements
import ClientLogo from '../elements/client-logo/client-logo-1';

// Images
import Banner1 from '../../images/banner/banner1.jpg';
import Icon1 from '../../images/icon/contact/icon1.png';
import Icon2 from '../../images/icon/contact/icon2.png';
import Icon3 from '../../images/icon/contact/icon3.png';

class GetInTouch extends Component {
	
	render(){
		return (
			<>
				<Header1 />
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark parallax" style={{backgroundImage: "url("+Banner1+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">Anket</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
										<li>Anket</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					<div className="content-block" id="content-area">
						
						<div className="section-area bg-gray section-sp2">
							<div className="container">
								<div className="row">
									<div className="col-lg-12 col-md-7">
										<div className="heading-bx">
											<h6 className="title-ext m-b0">Berlin Esnaf Anket</h6>
											<div className="ttr-separator sepimg"></div>
											<p className="head-px2">Anketimize Katılın.</p>
										</div>
										<div className="row m-b30" >
											<div className="col-md-12">
												<form className="ajax-form form-area-box get-in-touch" action="">
													<div className="ajax-message"></div>
												
														<div className="col-lg-12" >
															<div className="row m-b20" >
																<div className="col-md-12 col-lg-12">
																	<h5>SORU 1</h5>
																</div>
                                                                <div className="col-md-12 col-lg-12">
																	<div className="custom-control custom-checkbox m-b10">
																		<input type="checkbox" className="custom-control-input" id="customControlAutosizing1" />
																		<label className="custom-control-label" htmlFor="customControlAutosizing1">CEVAP</label>
																	</div>
																</div>
                                                                <div className="col-md-12 col-lg-12">
																	<div className="custom-control custom-checkbox m-b10">
																		<input type="checkbox" className="custom-control-input" id="customControlAutosizing2"/>
																		<label className="custom-control-label" htmlFor="customControlAutosizing2">CEVAP2</label>
																	</div>
																</div>
                                                                <div className="col-md-12 col-lg-12">
																	<div className="custom-control custom-checkbox m-b10">
																		<input type="checkbox" className="custom-control-input" id="customControlAutosizing3"/>
																		<label className="custom-control-label" htmlFor="customControlAutosizing3">CEVAP3</label>
																	</div>
																</div>
																<div className="col-md-12 col-lg-12">
																	<div className="custom-control custom-checkbox m-b10">
																		<input type="checkbox" className="custom-control-input" id="customControlAutosizing4"/>
																		<label className="custom-control-label" htmlFor="customControlAutosizing4">CEVAP4</label>
																	</div>
																</div>

															</div>
														</div>

                                                        <div className="col-lg-12">
															<div className="row m-b20">
																<div className="col-md-12 col-lg-12">
																	<h5>SORU 2</h5>
																</div>
                                                                <div className="col-md-12 col-lg-12">
																	<div className="custom-control custom-checkbox m-b10">
																		<input type="checkbox" className="custom-control-input" id="customControlAutosizing1" />
																		<label className="custom-control-label" htmlFor="customControlAutosizing1">CEVAP</label>
																	</div>
																</div>
                                                                <div className="col-md-12 col-lg-12">
																	<div className="custom-control custom-checkbox m-b10">
																		<input type="checkbox" className="custom-control-input" id="customControlAutosizing2"/>
																		<label className="custom-control-label" htmlFor="customControlAutosizing2">CEVAP2</label>
																	</div>
																</div>
                                                                <div className="col-md-12 col-lg-12">
																	<div className="custom-control custom-checkbox m-b10">
																		<input type="checkbox" className="custom-control-input" id="customControlAutosizing3"/>
																		<label className="custom-control-label" htmlFor="customControlAutosizing3">CEVAP3</label>
																	</div>
																</div>
                                                                <div className="col-md-12 col-lg-12">
																	<div className="custom-control custom-checkbox m-b10">
																		<input type="checkbox" className="custom-control-input" id="customControlAutosizing4"/>
																		<label className="custom-control-label" htmlFor="customControlAutosizing4">CEVAP4</label>
																	</div>
																</div>

															</div>
														</div>
													
														<div className="col-lg-12" style={{alignItems:'center', justifyContent:'center',display:'flex'}}>
															<button name="submit" type="submit" value="Submit" className="btn button-md"> GÖNDER</button>
														</div>
											
												</form>
											</div>
										</div>
									</div>
									
								</div>
								
							</div>
						</div>
						
					
						
					</div>
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}

export default GetInTouch;