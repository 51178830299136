import React, { Component } from 'react';
import { BrowserRouter, Route, Routes, Switch } from 'react-router-dom'

// Elements
import BackToTop from './elements/back-top';

import Index from './pages/';
import Index2 from './pages/index-2';
import Index3 from './pages/index-3';
import Index4 from './pages/index-4';
import About1 from './pages/about-1';
import About2 from './pages/about-2';
import About3 from './pages/about-3';
import Appointment from './pages/appointment';
import Awards from './pages/awards';
import BlogClassic from './pages/blog-classic';
import BlogClassicSidebar from './pages/blog-classic-sidebar';
import BlogDetailsSidebar from './pages/blog-details-sidebar';
import BlogList from './pages/blog-list';
import BlogMasonry from './pages/blog-masonry';
import BlogStandard from './pages/blog-standard';
import CaseDetails from './pages/case-details';
import CaseStudy from './pages/case-study';
import Client from './pages/client';
import ComingSoon from './pages/coming-soon';
import CompanyHistory from './pages/company-history';
import Contact1 from './pages/contact-1';
import Contact2 from './pages/contact-2';
import Error404 from './pages/error-404';
import Faq1 from './pages/faq-1';
import Faq2 from './pages/faq-2';
import GetInTouch from './pages/get-in-touch';
import JobCareer from './pages/job-career';
import MyAccount from './pages/my-account';
import Partners from './pages/partners';
import Pricing from './pages/pricing';

import ProjectsCarousel1 from './pages/projects-carousel-1';
import ProjectsCarousel2 from './pages/projects-carousel-2';
import ProjectsCarousel3 from './pages/projects-carousel-3';
import ProjectsCarousel4 from './pages/projects-carousel-4';
import ProjectsGrid2 from './pages/projects-grid-2';
import ProjectsGrid3 from './pages/projects-grid-3';
import ProjectsGrid4 from './pages/projects-grid-4';
import ProjectsGridWide from './pages/projects-grid-wide';
import ProjectsMasonryGrid2 from './pages/projects-masonry-grid-2';
import ProjectsMasonryGrid3 from './pages/projects-masonry-grid-3';
import ProjectsMasonryGrid4 from './pages/projects-masonry-grid-4';
import ProjectsMasonryGridWide from './pages/projects-masonry-grid-wide';
import ProjectsSingle1 from './pages/projects-single-1';
import ProjectsSingle2 from './pages/projects-single-2';
import ProjectsSingle3 from './pages/projects-single-3';
import ProjectsSingle4 from './pages/projects-single-4';
import Services1 from './pages/services-1';
import Services2 from './pages/services-2';
import Services3 from './pages/services-3';
import Ilan from './pages/ilan';
import News from './pages/news';
import ServicesDetails from './pages/services-details';
import NewsDetails from './pages/news-detail';
import Shop from './pages/shop';
import ShopCart from './pages/shop-cart';
import Rehberdetay from './pages/rehberdetay';
import Maincategory from './pages/maincategory';
import ShopCheckout from './pages/shop-checkout';
import ShopDetails from './pages/shop-details';
import ShopSidebar from './pages/shop-sidebar';
import SolutionAgriculturalEngineering from './pages/solution-agricultural-engineering';
import SolutionChemicalResearchEngineering from './pages/solution-chemical-research-engineering';
import SolutionMaterialScienceAndEngineering from './pages/solution-material-science-and-engineering';
import SolutionMechanicalEngineering from './pages/solution-mechanical-engineering';
import SolutionOilAndGas from './pages/solution-oil-and-gas';
import SolutionPowerAndEnergy from './pages/solution-power-and-energy';
import Team from './pages/team';
import Ilandetails from './pages/ilan-detail';
import Birbilen from './pages/birbilen';
import Birbilendetay from './pages/birbilendetay';
import Birbilenkonu from './pages/birbilenkonu';
import Aktuel from './pages/aktuel';
import Aktueldetay from './pages/aktueldetay';
import Rehberbasvur from './pages/rehber-basvur';
import Rehberbasvur2 from './pages/rehber-basvur2';
import Rehberbasvur2free from './pages/rehber-basvur2free';
import IlanAc from './pages/ilan-ac';
import IlanAc2 from './pages/ilan-ac2';
import IlanAc2free from './pages/ilan-ac2free';
import Rehber from './pages/rehber';
import Oyunlar from './pages/oyunlar';
import Muzik from './pages/muzik';
import Trafikdurumu from './pages/trafikdurumu';
import Rehberaltkategori from './pages/rehberaltkategori';
import Odeme1 from './pages/odeme1';
import Odeme2 from './pages/odeme2';
import Anket from './pages/anket';
import Signup from './pages/signup';
import 'moment/locale/tr'  // without this line it didn't work
import moment from 'moment'
import { listeners } from 'process';

class Markup extends Component{
	componentDidMount() {
		moment.locale('tr')
	}
	

	render(){
		return(
			
			<>
			
				<BrowserRouter basename={'/'}>
				
					<Routes>
					

						<Route path='/'  element={<Index/>} />
						<Route path={"/index-2"}  element={<Index2/>} />
						<Route path={"/ilan-detail/:id"}  element={<Ilandetails/>} />
						<Route path={"/birbilen"} element={<Birbilen/>} />
						<Route path={"/rehber" }element={<Rehber/>} />
						<Route path={"/birbilendetay/:id"}  element={<Birbilendetay />} />
						<Route path={"/birbilenkonu"} element={<Birbilenkonu />} />
						<Route path={"/index-3"}  element={<Index3/>} />
						<Route path={"/index-4"}  element={<Index4/>} />
						<Route path={"/about-1" }element={<About1/>} />
						<Route path={"/about-2"} element={<About2/>} />
						<Route path={"/odeme1"} element={<Odeme1/>} />
						<Route path={"/odeme2"} element={<Odeme2/>} />
						<Route path={"/ilan"} element={<Ilan/>} />
						<Route path={"/rehberdetay/:id"} element={<Rehberdetay/>} />
						<Route path={"/rehberaltkategori/:id"} element={<Rehberaltkategori/>} />
						<Route path={"/maincategory"} element={<Maincategory/>} />
						<Route path={"/appointment"} element={<Appointment/>} />
						<Route path={"/awards"} element={<Awards/>} />
						<Route path={"/anket"} element={<Anket/>} />
						<Route path={"/signup"} element={<Signup/>} />
						<Route path={"/blog-classic"} element={<BlogClassic/>} />
						<Route path={"/blog-classic-sidebar"} element={<BlogClassicSidebar/>} />
						<Route path={"/blog-details-sidebar"} element={<BlogDetailsSidebar/>} />
						<Route path={"/blog-list"} element={<BlogList/>} />
						<Route path={"/blog-masonry"} element={<BlogMasonry/>} />
						<Route path={"/blog-standard"} element={<BlogStandard/>} />
						<Route path={"/case-details"} element={<CaseDetails/>} />
						<Route path={"/case-study"} element={<CaseStudy/>} />
						<Route path={"/client"} element={<Client/>} />
						<Route path={"/coming-soon"} element={<ComingSoon/>} />
						<Route path={"/company-history"} element={<CompanyHistory/>} />
						<Route path={"/contact-1"} element={<Contact1/>} />
						<Route path={"/contact-2"} element={<Contact2/>} />
						<Route path={"/error-40"} element={<Error404/>} />
						<Route path={"/faq-1"} element={<Faq1/>} />
						<Route path={"/faq-2"} element={<Faq2/>} />


						<Route path={"/get-in-touch"} element={<GetInTouch/>} />
						<Route path={"/job-career"} element={<JobCareer/>} />
						<Route path={"/my-accoun"} element={<MyAccount/>} />
						<Route path={"/partners"} element={<Partners/>} />
						<Route path={"/pricin"} element={<Pricing/>} />
						<Route path={"/projects-carousel-1"} element={<ProjectsCarousel1/>} />
						<Route path={"/projects-carousel-2"} element={<ProjectsCarousel2/>} />
						<Route path={"/projects-carousel-3"} element={<ProjectsCarousel3/>} />
						<Route path={"/projects-carousel-4"} element={<ProjectsCarousel4/>} />
						<Route path={"/projects-grid-2"} element={<ProjectsGrid2/>} />
						<Route path={"/projects-grid-3"} element={<ProjectsGrid3/>} />
						<Route path={"/projects-grid-4"} element={<ProjectsGrid4/>} />
						<Route path={"/projects-grid-wide"} element={<ProjectsGridWide/>} />
						<Route path={"/projects-masonry-grid-2"}element={<ProjectsMasonryGrid2/>} />
						<Route path={"/projects-masonry-grid-3" }element={<ProjectsMasonryGrid3/>} />
						<Route path={"/projects-masonry-grid-4" }element={<ProjectsMasonryGrid4/>} />
						<Route path={"/projects-masonry-grid-wide"} element={<ProjectsMasonryGridWide/>} />
						<Route path={"/projects-single-1"} element={<ProjectsSingle1/>} />
						<Route path={"/projects-single-2"} element={<ProjectsSingle2/>} />
						<Route path={"/projects-single-3" }element={<ProjectsSingle3/>} />
						<Route path={"/projects-single-4"} element={<ProjectsSingle4/>} />
						<Route path={"/services-1"} element={<Services1/>} />
						<Route path={"/news"} element={<News/>} />
						<Route path={"/services-2"} element={<Services2/>} />
						<Route path={"/services-3" }element={<Services3/>} />
						<Route path={"/services-details" }element={<ServicesDetails/>} />
						<Route path={"/news-detail"} element={<NewsDetails/>} />
						<Route path={"/shop" }element={<Shop/>} />
						<Route path={"/shop-cart"} element={<ShopCart/>} />
						<Route path={"/rehber-basvur"} element={<Rehberbasvur/>} />
						<Route path={"/rehber-basvur2"} element={<Rehberbasvur2/>} />
						<Route path={"/rehber-basvur2free"} element={<Rehberbasvur2free/>} />
						<Route path={"/shop-checkout"} element={<ShopCheckout/>} />
						<Route path={"/shop-details"} element={<ShopDetails/>} />
						<Route path={"/shop-sidebar"} element={<listenersShopSidebar/>} />




						<Route path={"/solution-agricultural-engineering"} element={<SolutionAgriculturalEngineering/>} />
						<Route path={"/solution-chemical-research-engineering"} element={<SolutionChemicalResearchEngineering/>} />
						<Route path={"/solution-material-science-and-engineering"} element={<SolutionMaterialScienceAndEngineering/>} />
						<Route path={"/solution-mechanical-engineering"} element={<SolutionMechanicalEngineering/>} />
						<Route path={"/solution-oil-and-gas"} element={<SolutionOilAndGas/>} />
						<Route path={"/solution-power-and-energy"} element={<SolutionPowerAndEnergy/>} />
						<Route path={"/team"} element={<Team/>} />					
						<Route path={"/aktuel"} element={<Aktuel/>} />	
						<Route path={"/aktueldetay/:id"} element={<Aktueldetay/>} />	
						<Route path={"/ilan-ac"} element={<IlanAc/>} />	
						<Route path={"/ilan-ac2"} element={<IlanAc2/>} />	
						<Route path={"/ilan-ac2free"} element={<IlanAc2free/>} />	
						<Route path={"/oyunlar"} element={<Oyunlar/>} />
						<Route path={"/muzik"} element={<Muzik/>} />
						<Route path={"/trafikdurumu"} element={<Trafikdurumu/>} />
					</Routes>
				</BrowserRouter>
				
				<BackToTop />
			
			</>
		);
	}
}

export default Markup;
