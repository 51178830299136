import React, { Component,useState,handleShow } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';

// Images
import Logo from "../../../images/logo.png"
import ProductImg1 from "../../../images/product/pic1.jpg"
import ProductImg2 from "../../../images/product/pic2.jpg"

class Header1 extends Component{
	
	componentDidMount() {
		
		// Cart Dropdown 
		var cartBtn = document.getElementById("cartBtn")
        var cartDropdown = document.querySelector(".cart-dropdown")
		
        cartBtn.addEventListener('click',function(){
            cartDropdown.classList.toggle("show")
		})
		
		// Search Form Popup
		var searchBtn = document.getElementById("quik-search-btn")
        var searchForm = document.querySelector(".nav-search-bar")
        var closeBtn = document.getElementById("search-remove")
		
        searchBtn.addEventListener('click',function(){
            searchForm.classList.add("show")
        })

        closeBtn.addEventListener('click',function(){
            searchForm.classList.remove("show")
        })

        // Mobile Menu sidebar function
        var btn = document.querySelector('.menuicon');
        var nav = document.querySelector('.menu-links');
       
        function toggleFunc() {
            btn.classList.toggle("open");
            nav.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);

        // Mobile Submenu open close function
        var navMenu = [].slice.call(document.querySelectorAll('.menu-links > ul > li'));
        for (var y = 0; y < navMenu.length; y++) {
            navMenu[y].addEventListener('click', function () { menuClick(this) });
        }

        function menuClick(current) {
            const active = current.classList.contains("open")
            navMenu.forEach(el => el.classList.remove('open'));
            
            if(active){
                current.classList.remove('open') 
                console.log("active")
            } else{
                current.classList.add('open');
                console.log("close")
            }
        }
		
    }
	
	render(){
		return(
			<>
				
				{/* <!-- Header Top ==== --> */}
				<header className="header rs-nav">
					<div className="top-bar">
						<div className="container">
							<div className="row d-flex justify-content-between">
								<div className="topbar-left">
									<ul>
										<li><Link to="#"><i className="la la-phone"></i>Call Us (+00) 286 8591</Link></li>
										<li><Link to="#"><i className="la la-clock"></i>Mon to Fri - 9:00am - 6:00pm</Link></li>
										<li><Link to="#"><i className="las la-envelope-open"></i>Support@website.com</Link></li>
									</ul>
								</div>
								<div className="topbar-right">
									<ul>
										<li><Link to="company-history">History</Link></li>
										<li><Link to="blog-standard">News</Link></li>
										<li><Link to="contact-1">Contact Us</Link></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<Sticky enabled={true} className="sticky-header navbar-expand-lg">
						<div className="menu-bar clearfix">
							<div className="container clearfix">
								{/* <!-- Header Logo ==== --> */}
								<div className="menu-logo">
									<Link to="/"><img src={Logo} alt=""/></Link>
								</div>
								{/* <!-- Mobile Nav Button ==== --> */}
								<button className="navbar-toggler collapsed menuicon justify-content-end" type="button" data-toggle="collapse" data-target="#menuDropdown" aria-controls="menuDropdown" aria-expanded="false" aria-label="Toggle navigation">
									<span></span>
									<span></span>
									<span></span>
								</button>
								{/* <!-- Author Nav ==== --> */}
								<div className="secondary-menu">
									<div className="secondary-inner">
										<ul>
											<li className="search-btn">
												<button id="quik-search-btn" type="button" className="btn-link"><i className="ti-search"></i></button>
											</li>
											<li>
												<Link to="#" className="cart-btn" id="cartBtn">
													<span>0</span>
												</Link>
												<ul className="dropdown-menu cart-dropdown cart-list">
													<li className="cart-item">
														<div className="media"> 
															<div className="media-left"> 
																<Link to="#"> 
																	<img alt="" className="media-object" src={ProductImg1}/> 
																</Link> 
															</div> 
															<div className="media-body"> 
																<h6 className="title"><Link to="" className="media-heading">There are many variations of passages</Link></h6>
																<span className="price">$25.00</span>
																<span className="item-close">&times;</span>
															</div> 
														</div>
													</li>
													<li className="cart-item">
														<div className="media"> 
															<div className="media-left"> 
																<Link to="#"> 
																	<img alt="" className="media-object" src={ProductImg2}/> 
																</Link> 
															</div> 
															<div className="media-body"> 
																<h6 className="title"><Link to="#" className="media-heading">There are many variations of passages</Link></h6>
																<span className="price">$22.00</span>
																<span className="item-close">&times;</span>
															</div> 
														</div>
													</li>
													<li className="cart-item subtotal">
														<h6 className="title m-a0">Subtotal <span>$47.00</span></h6>
													</li>
													<li className="d-flex">
														<button className="btn button-md flex-fill m-r5">View Cart</button>
														<button className="btn-secondry button-md flex-fill m-l5">Checkout</button>
													</li>
												</ul>
											</li>
											<li className="d-none d-sm-block">
												<Link to="get-in-touch" className="btn">Get In Touch</Link>
											</li>
										</ul>
									</div>
								</div>
								{/* <!-- Navigation Menu ==== --> */}
								<div className="menu-links navbar-collapse collapse justify-content-end" id="menuDropdown">
									<div className="menu-logo">
										<Link to="/"><img src={Logo} alt=""/></Link>
									</div>
									<ul className="nav navbar-nav">	
										
									<li className="active"><Link to="index-2">Anasayfa</Link>
										
										</li>

										<li><Link to="about-1">Hakkımızda</Link>
										
										</li>

										<li><Link to="rehber">Rehber</Link>
										
										</li>

										<li><Link to="news">Aktüel</Link>
										
										</li>

										<li><Link to="ilan">İlan</Link>
										
										</li>
											
										<li><Link to="birbilen">Bir Bilen</Link>
										
										</li>
										<li><Link to="#">Shop<i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu">
												<li className="add-menu-left">
													<ul>
														<li><Link to="shop"><span>Shop</span> </Link></li>
														<li><Link to="shop-sidebar"><span>Shop Sidebar</span></Link></li>
														<li><Link to="shop-cart"><span>Cart</span></Link></li>
														<li><Link to="shop-checkout"><span>Checkout</span></Link></li>
														<li><Link to="shop-details"><span>Product Details</span></Link></li>
													</ul>
												</li>
											</ul>
										</li>
										<li><Link to="#">Blog <i className="fa fa-chevron-down"></i></Link>
											<ul className="sub-menu left">
												<li><Link to="blog-standard"><span>Blog Standard</span></Link></li>
												<li><Link to="blog-classic"><span>Blog Classic</span></Link></li>
												<li><Link to="blog-classic-sidebar"><span>Blog Classic Sidebar</span></Link></li>
												<li><Link to="blog-list"><span>Blog List Sidebar</span></Link></li>
												<li><Link to="blog-masonry"><span>Masonry</span></Link></li>
												<li><Link to="blog-details-sidebar"><span>Blog Details</span></Link></li>
											</ul>
										</li>
									</ul>
									<div className="nav-social-link">
										<Link to="#"><i className="fa fa-facebook"></i></Link>
										<Link to="#"><i className="fa fa-google-plus"></i></Link>
										<Link to="#"><i className="fa fa-linkedin"></i></Link>
									</div>
								</div>
								{/* <!-- Navigation Menu END ==== --> */}
							</div>
						</div>
					</Sticky>
					{/* <!-- Search Box ==== --> */}
					<div className="nav-search-bar">
						<form action="#">
							<input name="search" type="text" className="form-control" placeholder="Type to search"/>
							<span><i className="ti-search"></i></span>
						</form>
						<span id="search-remove"><i className="ti-close"></i></span>
					</div>

	
				</header>
				{/* <!-- Header Top END ==== --> */}
			</>
		);
	}
}

export default Header1;
