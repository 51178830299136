import React, { Component } from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Logo from "./images/be.png"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

};

export default function (props) {
  return (

    <Modal
      open={props.show}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box className='' sx={style}>

        <div className="bg-[black] justify-center items-center text-center  self-center content-center justify-items-center ">

          <div className='flex  justify-center items-center text-center'>

            <img src={Logo} alt="/" className="p-1 justify-center items-center w-1/3 bg-white " />
          </div>


        </div>

      </Box>
    </Modal>
  );

}


