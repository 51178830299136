import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Layout
import Header1 from '../layout/header/header2';
import Footer1 from '../layout/footer/footer1';
import { withParamsAndNavigate } from "../../getParamsAndNavigate.js";
// Images
import Banner1 from '../../images/banner/banner1.jpg';

import PortImage10 from '../../images/portfolio/image_10.jpg';
import ReactHtml from 'raw-html-react';
const imgUrl = "https://berlinesnafadmin.stechomeyazilim.com/Uploads/";

class CaseDetails extends Component {
	
	render(){
		return (
			<>
				<Header1 />
				
				{/* <!-- Content --> */}
				<div className="page-content bg-white">
					
					<div className="page-banner ovbl-dark parallax" style={{backgroundImage: "url("+Banner1+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">İlan Detayları</h1>
								<div className="breadcrumb-row">
									<ul className="list-inline">
										<li><Link to="/"><i className="fa fa-home"></i> Anasayfa</Link></li>
										<li>İlan Detayları</li>
									</ul>
								</div>
							 </div>
						</div>
					</div>
					
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp2 port-7-content">
							<div className="container">
								<div className="row">
									<div className="col-lg-7">
										<div className="heading-bx text-left m-b20" >
											<h3 className="title-head m-b0" >
											
											<span style={{backgroundColor:'#FDCC03',color:'white',padding: 5, fontSize:16}}>{this.props.location.state._item.Job_Advertisement_SubCategory.Job_Advertisement_SubCategory_Text} - {this.props.location.state._item.Job_Advertisement_SubCategory.Job_Advertisement_Category.Job_Advertisement_Category_Title}	</span>
									
											<span style={{backgroundColor:'black',color:'white',padding: 5, fontSize:16,margin:5}}><i className='fa fa-eye'></i>{this.props.location.state._item.Job_Advertisement_Seen}	</span>
										
											<ReactHtml html={this.props.location.state._item.Job_Advertisement_Title}/>
			
												</h3>
											<div className="ttr-separator sepimg">
													
											</div>
										</div>
										
								
									


										<p>
											
										<ReactHtml html={this.props.location.state._item.Job_Advertisement_Desc}/>
											</p>
										
								
									
									</div>
									<div className="col-lg-5">
										<div className="row sp4 magnific-image sticky-top">
										
											<div className="action-card col-lg-12 col-md-12 col-sm-12 col-12 wow fadeInUp" data-wow-delay="0.6s">
												<div className="portfolio-box style-2">
												{this.props.location.state._item.Job_Advertisement_Image.slice(0,1).map(item2 => (
													<div className="portfolio-media">
														<img src={imgUrl+item2.Job_Advertisement_Image_Text} alt=""/>
													</div>		))}

													<Link to="projects-single-1" className="magnific-anchor">View Details</Link>
												</div>
											</div>
										
										</div>
									</div>
								</div>
								<br/>
								{
								this.props.location.state._item.Job_Advertisement_Image.map(item2 => (
								
								<div className="col-lg-12" style={{margin:5}}>
									<img src={imgUrl+item2.Job_Advertisement_Image_Text} alt=""/>
								</div>	
								
							
									))}
							</div>
						</div>

						
						
					</div>
						
				
					
				</div>
				
				<Footer1 />
				
			</>
		);
	}
}

export default withParamsAndNavigate(CaseDetails); 